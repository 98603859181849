<template>
    <div :class="itemIndex === 0 ? 'card-style1' : 'card-style2'">
        <div :class="itemIndex === 0?'resource-top-style':''"></div>
        <div class="resource-header-style">
            <div style="display: flex;flex-direction: row;justify-content: space-between;width: 100%">
                <div class="resource-name-container-style">
                    <div style="display: flex;flex-direction: row;align-items: baseline">
                        <div class="resource-name-style">{{ name }}</div>
                        <div class="resource-e-style">{{ eName }}</div>
                    </div>

                </div>
                <button class="btn-style" @click="navigateToPath">查看更多</button>
            </div>
        </div>
        <div class="resource-img-container-style">
            <div v-for="(item,index) in itemContent" :key="index"  @click="navigateToDetail(item)">
                <img class="resource-img-style" :src="item.preview_image" alt="资源图片">
                <div class="resource-title-container-style"
                     style="display: flex;flex-direction: row;justify-content: space-between;align-items: center">
                    <div class="resource-title-style">{{ item.title }}</div>
                    <div style="display: flex;flex-direction: row">
                        <!--会-->
                        <img v-if="item.origin === 5" src="../assets/vip.png" alt="vip" class="resource-icon-style">
                        <!--优-->
                        <img v-if="item.is_pay === 1" src="../assets/good.png" alt="good" class="resource-icon-style">
                        <!--商-->
                        <img v-if="item.is_bus === 1" src="../assets/business.png" alt="business" class="resource-icon-style">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ResourceItem",
        props: ["itemIndex", "itemContent","name","eName"],
        data() {
            return {
                items: [
                    {
                        imgUrl: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2F977804ad33f99a599e4f2a555fd0b4f442cd195f5449-ER3bQR_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1618397752&t=23bca307211386654e21ed68ec5625c2',
                        name: '蓝色科技PPT模板',
                        status: ''
                    },
                    {
                        imgUrl: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2F977804ad33f99a599e4f2a555fd0b4f442cd195f5449-ER3bQR_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1618397752&t=23bca307211386654e21ed68ec5625c2',
                        name: '蓝色科技PPT模板',
                        status: ''
                    },
                    {
                        imgUrl: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2F977804ad33f99a599e4f2a555fd0b4f442cd195f5449-ER3bQR_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1618397752&t=23bca307211386654e21ed68ec5625c2',
                        name: '蓝色科技PPT模板',
                        status: ''
                    },
                    {
                        imgUrl: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2F977804ad33f99a599e4f2a555fd0b4f442cd195f5449-ER3bQR_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1618397752&t=23bca307211386654e21ed68ec5625c2',
                        name: '蓝色科技PPT模板',
                        status: ''
                    },
                    {
                        imgUrl: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2F977804ad33f99a599e4f2a555fd0b4f442cd195f5449-ER3bQR_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1618397752&t=23bca307211386654e21ed68ec5625c2',
                        name: '蓝色科技PPT模板',
                        status: ''
                    },
                    {
                        imgUrl: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2F977804ad33f99a599e4f2a555fd0b4f442cd195f5449-ER3bQR_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1618397752&t=23bca307211386654e21ed68ec5625c2',
                        name: '蓝色科技PPT模板',
                        status: ''
                    },
                ]
            }
        },
        mounted() {

        },
        methods: {
            navigateToDetail(item) {
                console.log(item);
                this.$router.push({ path:'/item_detail', query:{ id:item.id} })



            },
            navigateToPath: function () {
                // this.$router.push({
                //     name: 'HotTab'
                // })


                let sort = null;

                if(this.name === "热门素材"){
                    sort = 'hot';
                }else if(this.name === '最新素材'){
                    sort = 'new';
                }
                let para = {module: 0, type: 0, industry: 0, style: 0, color: 0};
                if(sort) {
                    para.sort = sort;
                }

                if(this.name === "PPT模板") {
                  para.type = 1
                }else if(this.name === "Excel图表") {
                  para.type = 2
                }else if(this.name === "免抠") {
                  para.type = 3
                }else if(this.name === "图标") {
                  para.type = 4
                }else if(this.name === "图片") {
                  para.type = 5
                }else if(this.name === "3D") {
                  para.type = 6
                }else if(this.name === "视频") {
                  para.type = 7
                }

                this.$router.push({
                    path: "/hot_tab",
                    query: para,
                });

            },
        }
    }
</script>

<style scoped>
    .card-style1 {
        border-radius: 0 0 10px 10px;
        box-shadow: 0 11px 21px 2px rgba(81, 130, 255, 0.1);
        height: 660px;
        background: white;
    }

    .card-style2 {
        border-radius: 10px;
        box-shadow: 0 11px 21px 2px rgba(81, 130, 255, 0.1);
        height: 600px;
        margin-top: 45px;
        background: white;
    }

    .resource-top-style {
        height: 60px;
    }

    .resource-header-style {
        display: flex;
        justify-content: center
    }

    .resource-name-container-style {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 48px;
        margin: 16px 0 36px 74px;
    }

    .resource-name-style {
        font-weight: bold;
        color: #3C434D;
        font-size: 22px;
    }

    .resource-e-style {
        font-weight: 500;
        color: #3C434D;
        opacity: 0.3;
        font-size: 20px;
        margin-left: 12px;
    }

    .btn-style {
        font-weight: 400;
        border: none;
        outline: none;
        color: white;
        cursor: pointer;
        background: linear-gradient(-90deg, #27BAFA, #4253E3);
        box-shadow: 0 4px 9px 0 rgba(42, 176, 248, 0.6);
        width: 100px;
        height: 48px;
        font-size: 18px;
        border-radius: 24px;
        margin-top: 16px;
        margin-bottom: 36px;
        margin-right: 76px;
    }

    .resource-img-container-style {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin: 0 76px;
        cursor: pointer;
    }

    .resource-img-style {
        width: 320px;
        height: 180px;
        border-radius: 10px;
        /*object-fit:cover;*/

    }

    .resource-title-container-style {
        margin-top: 7px;
        margin-bottom: 27px;
      width: 320px;
    }

    .resource-title-style {
        font-size: 14px;
        font-weight: bold;
        color: #3C434D;
      width: 70%;
    }

    .resource-icon-style {
        width: 30px;
        height: 30px;
    }
</style>
