<template>
    <div v-loading="loading">
        <HotTabHeader :type="type"/>
        <div class="hot-container-style">
            <div>
                <div v-for="(item,index) in items" :key="index" class="hot-item-style" @click="navigateToDetail(item)">
                    <img :src="item.preview_image" alt="img">
                    <div class="hot-name-container-style">
                        <div>{{item.title}}</div>
                      <div style="display: flex;flex-direction: row">
                        <!--会-->
                        <img v-if="item.origin === 5" src="../assets/vip.png" alt="vip" class="resource-icon-style">
                        <!--优-->
                        <img v-if="item.is_pay === 1" src="../assets/good.png" alt="good" class="resource-icon-style">
                        <!--商-->
                        <img v-if="item.is_bus === 1" src="../assets/business.png" alt="business" class="resource-icon-style">
                      </div>


                    </div>
                </div>
                <div class="page-container-style">
                    <el-pagination
                            background
                            @current-change="handleCurrentChange"
                            :current-page.sync="currentPage"
                            layout="prev, pager, next,jumper"
                            :page-size="pageSize"
                            :total="totalNum">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HotTabHeader from "@/components/HotTabHeader";

    export default {
        name: "HotTab",
        components: {HotTabHeader},
        data() {
            return {
                items: [],
                currentPage:1,
                pageSize:21,
                totalNum:0,
                loading: false,
                type:parseInt(this.$route.query.type),//其他界面传过来的素材类型
                sort:this.$route.query.sort
            }
        },
        mounted() {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            this.loadData();
            // this.type = parseInt(this.$route.query.type);//其他界面传过来的素材类型
            // console.log(this.type);
        },
        watch: {
            $route(to, from) {
                if (this.$route.query.type) {
                    this.type = parseInt(this.$route.query.type);//其他界面传过来的素材类型
                    this.loadData();

                }
            },


            beginSearch(val) {
                let parameter = {
                    keywords:this.$store.state.searchData,
                    module:this.$store.state.searchModule,
                    type:this.$store.state.searchType,
                    industry:this.$store.state.searchIndustry,
                    style:this.$store.state.searchStyle,
                    color:this.$store.state.searchColor,
                };


                this.$router.push({path:'/hot_tab',query:parameter})
            }

        },
        computed: {
            beginSearch() {
                return this.$store.state.beginSearch;
            }
        },

        methods: {

            navigateToDetail(item) {
                this.$router.push({
                    path: "/item_detail",
                    query:{id:item.id}
                })
            },
            handleCurrentChange(val) {
                // 点击每页时触发
                this.currentPage = val;
                this.loadData();
            },

            loadData: function () {
                console.log('开始搜索');
                console.log(this.$store.state.searchData);
                console.log(this.$store.state.searchType);
                console.log(this.$store.state.searchIndustry);
                console.log(this.$store.state.searchStyle);
                console.log(this.$store.state.searchColor);
                console.log(this.$store.state.searchModule);

                let parameter = {
                    per_page:this.pageSize,
                    page:this.currentPage,
                    keywords:this.$store.state.searchData,
                    module: this.stringIsEmpty(this.$store.state.searchModule) ? 0 : this.$store.state.searchModule,
                    type:this.stringIsEmpty(this.$store.state.searchType) ? 0 : this.$store.state.searchType,
                    industry:this.stringIsEmpty(this.$store.state.searchIndustry) ? 0 : this.$store.state.searchIndustry,
                    style:this.stringIsEmpty(this.$store.state.searchStyle) ? 0 : this.$store.state.searchStyle,
                    color:this.stringIsEmpty(this.$store.state.searchColor) ? 0 : this.$store.state.searchColor,
                };
                if(!this.stringIsEmpty(this.sort)){
                    parameter.sort = this.sort;
                }

                this.loading = true;
                this.$get("/webapi/material/grid", parameter)
                    .then(res => {

                        if (res.code === 1) {
                            this.totalNum = res.data.total;
                            this.items = res.data.data
                        } else {
                            this.items = [];
                            console.log(res.msg)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        }
    }
</script>

<style scoped>
    .hot-container-style {
        margin-top: 30px;
        padding-bottom: 76px;
        background: white;
        box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
        border-radius: 10px;
        width: 100%;
    }


    .hot-container-style > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 0 76px;
    }

    .hot-item-style {
        margin-top: 35px;
        cursor: pointer;
    }

    .hot-item-style > img {
        width: 319px;
        height: 180px;
        border-radius: 10px;
        display: inline-block;
    }

    .hot-name-container-style {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      width: 320px;

    }

    .hot-name-container-style > div:first-child {
        font-size: 14px;
        font-weight: bold;
        color: #3C434D;
      width: 70%;
    }

    .hot-name-container-style > div:nth-child(2) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .hot-name-container-style > div:nth-child(2) > img {
        width: 24px;
        height: 24px;
        margin: 9px 0;
    }

    .page-container-style {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 30px;
    }
</style>
