<template>
  <div class="my-card-style">
    <div class="focus-title-container">
      <div>我的资产</div>
      <div>My assets</div>
    </div>
    <div class="focus-line-style"></div>

    <div v-if="isHaveAssets">
      <div class="money-container-style">
        <div>
          <div style="font-size: 26px">总资产：20元</div>
          <div style="font-size: 20px; opacity:0.7;margin-top: 20px">累计收益:100 已提现:80 待提现:20</div>
        </div>
        <div>提现</div>
      </div>

      <div style="display: flex;justify-content: space-between;align-items: center">
        <div>
          <div style="display: flex;align-items: center" class="input-container-style">
            <div class="number-style">编号 <el-input v-model="sn" type="text" size="medium" style="width: 180px"></el-input> </div>
            <div class="number-style3">类型
              <el-select v-model="type" size="medium" style="width: 180px" placeholder="请选择文件类型">
                <el-option
                    v-for="item in typeOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>

            </div>
          </div>
          <div class="number-style2">日期
            <el-date-picker
                class="date-picker"
                style="width: 200px"
                size="medium"
                v-model="startTime"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
            至
            <el-date-picker
                style="width: 200px"
                class="date-picker"
                size="medium"
                v-model="endTime"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
        <div class="search-style" @click="searchClick">查询</div>
      </div>




      <div class="record-style">
        <div @click="incomeClick" :class="[isIncome ? 'select-style' : 'no-select-style']" >收入记录</div>
        <div @click="withdrawClick" :class="[isIncome ? 'no-select-style' : 'select-style']">提现记录</div>


      </div>
      <el-table
          v-if="isIncome"
          :header-row-style="{backgroundColor: '#F2F3F3'}"
          class="table-container-style"
          :data="incomeTableData">
        <el-table-column
            key="1"
            align="center"
            prop="designer_income"
            label="收入金额">
        </el-table-column>
        <el-table-column
            key="2"
            align="center"
            prop="order_no"
            label="订单编号">
        </el-table-column>
        <el-table-column
            key="3"
            align="center"
            prop="material_sn"
            label="作品编号">
        </el-table-column>
        <el-table-column
            key="4"
            align="center"
            prop="material.type_name"
            label="素材类型">
        </el-table-column>
        <el-table-column
            key="5"
            align="center"
            prop="created_at"
            label="时间">
          <template #default="scope">
            {{
              getDate(scope.row.created_at)
            }}
          </template>
        </el-table-column>
      </el-table>


      <el-table
          v-else
          :header-row-style="{backgroundColor: '#F2F3F3'}"
          class="table-container-style"
          :data="withdrawTableData">
        <el-table-column
            key="6"
            align="center"
            prop="extracted_money"
            label="提现金额">
        </el-table-column>
        <el-table-column
            key="7"
            align="center"
            prop="extracted_no"
            label="提现单号">
        </el-table-column>
        <el-table-column
            key="8"
            align="center"
            prop="extracted_type"
            label="提现方式">
        </el-table-column>
        <el-table-column
            key="9"
            align="center"
            prop="name"
            label="时间">
          <template #default="scope">
            {{
              getDate(scope.row.created_at)
            }}
          </template>
        </el-table-column>
      </el-table>



      <div class="bottom" style="padding: 0 40px">
        <div class="message">共<i class="blue">{{ totalNum }}</i>条记录，当前显示第&nbsp;<i class="blue">{{currentPage}}</i> 页
        </div>
        <!--下面是element-ui的分页-->
        <el-pagination class="el-pagin"
                       layout="prev, pager, next"
                       :current-page="currentPage"
                       :total="totalNum"
                       :page-size="pageSize"
                       @current-change="handleCurrentChange">
        </el-pagination>
      </div>

    </div>

    <div v-else style="text-align: center;margin: 30px;font-size: 26px">
    <div class="top-item">
      <img width="25%" src="../assets/no_money_top.png" alt="">
      <span class="top-text" style="color: #BBBBBB;font-size: 14px;">您还没有资产哦!</span>
    </div>

      <div>
        <img width="70%" src="../assets/no_money_bottom.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyMoney",
  data() {
    return {
      isHaveAssets: true,
      sn: "",
      type: 0,
      startTime:'',
      endTime:'',

      pageSize:20,

      isIncome:true,//是否选中收入记录

      typeOptions: [],
      currentPage: 1,
      totalNum:0,
      incomeTableData: [],//收入记录
      withdrawTableData: [] //提现记录

    }
  },
  mounted() {
    this.getTypeOptions();
    this.loadData();
  },

  methods: {

    getDate(dateTime) {
      let arr = dateTime.split(' ');
      if (arr.length > 0) {
        return arr[0]
      }
      return ""
    },

    incomeClick(){
      this.isIncome = true
      console.log(this.isIncome);
      this.loadData();

    },
    withdrawClick() {
      this.isIncome = false
      console.log(this.isIncome);
      this.loadData();


    },
    searchClick() {
      this.loadData();

    },

    loadData() {
      let url = '/webapi/order/revenue_record';

      let parameter = {
        per_page: this.pageSize,
        page: this.currentPage,

      };
      if(this.isIncome){
        parameter.scene = 'income_order'
      }else{
        parameter.scene = 'extracted_order'
      }
      if(!this.stringIsEmpty(this.sn)){
        parameter.sn = this.sn;
      }
      if(!this.stringIsEmpty(this.type)){
        parameter.type = this.type;
      }

      if(!this.stringIsEmpty(this.startTime)){
        parameter.startTime = this.startTime;
      }

      if(!this.stringIsEmpty(this.endTime)){
        parameter.endTime = this.endTime;
      }

      this.$get(url, parameter).then(resp => {
        if(this.isIncome){
          this.incomeTableData =  resp.data.data;
          if (this.incomeTableData.length > 0){
            this.isHaveAssets = true;
            //测试
            this.isHaveAssets = false;

          }else{
            this.isHaveAssets = false;
          }
        }else{
          // created_at: "2021-12-05 12:01:01"
          // extracted_money: "100.00"
          // extracted_no: "Tlskdjfli89w47skdjf"
          // extracted_type: "微信"
          this.withdrawTableData =  resp.data.data;
        }

        this.totalNum = resp.data.total;
      })
    },
    handleCurrentChange(val) {
      // 点击每页时触发
      this.currentPage = val;
      this.loadData();
    },
    getTypeOptions() {
      let url = "/webapi/material/tabs";
      this.$get(url, {t: 'type'}).then(resp => {
        this.typeOptions.push({id: 0, name: "全部"})
        this.typeOptions.push(...resp.data)

        // this.typeOptions = resp.data;


      })
    }
  },


}
</script>

<style scoped>
.my-card-style {
  height: 950px;
  background: #FFFFFF;
  box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
  border-radius: 10px;
}

.focus-title-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding-top: 30px;
  width: 100%;
}

.focus-title-container > div:first-child {
  margin-left: 60px;
  font-size: 22px;
  font-weight: bold;
  color: #3C434D;
}

.focus-title-container > div:last-child {
  font-size: 20px;
  font-weight: 500;
  color: #3C434D;
  opacity: 0.3;
  margin-left: 12px;
}

.focus-line-style {
  margin-top: 30px;
  height: 1px;
  width: 100%;
  background: #F4F4F4;
}

.money-container-style {
  width: 742px;
  height: 140px;
  background: linear-gradient(-90deg, #27BAFA, #4253E3);
  box-shadow: 0 4px 9px 0 rgba(42, 176, 248, 0.6);
  border-radius: 5px;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.money-container-style > div:first-child {
  font-size: 22px;
  font-weight: 500;
  color: #FFFFFF;
  margin-left: 30px;
}

.money-container-style > div:last-child {
  width: 100px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 24px;
  font-size: 24px;
  font-weight: 400;
  color: #0084FF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

.record-style {
  display: flex;
  flex-direction: row;
  padding-left: 55px;
  margin: 20px 0;
}

.table-container-style {
  width: 742px;
  margin: 10px auto;
}

.number-style {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}

.number-style2 {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  margin-left: 80px;
  margin-top: 30px;
}

.number-style3 {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  margin-left: 30px;
  /*margin-top: 25px;*/
}



.search-style {
  margin: 0px 90px 0 0;
  width: 82px;
  height: 30px;
  background: linear-gradient(-90deg, #27BAFA, #4253E3);
  border: 1px solid #0084FF;
  box-shadow: 0 4px 9px 0 rgba(42, 176, 248, 0.6);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-style{
  width: 100px;
  height: 35px;
  background: linear-gradient(-90deg, #27BAFA, #4253E3);
  border: 1px solid #0084FF;
  box-shadow: 0 4px 9px 0 rgba(42, 176, 248, 0.6);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-right: 5px;
}
.no-select-style{
  width: 100px;
  height: 35px;
  background: #DCDCDC;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;

}
.input-container-style {
  margin-left: 80px;
}
.top-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 30px;
}
.top-text{
  position: absolute;
  /*top: -50px;*/
  bottom: 15px;
}

</style>
