import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HotTab from '../views/HotTab'
import Material from "../views/Material";
import BottomItemView from "../components/BottomItemView"

import Designer from "../views/Designer";
import Message from "../views/Message";
import Me from "../views/Me";
import MyDetail from "../components/MyDetail";
import MyVip from "../components/MyVip";
import MyDownload from "../components/MyDownload";
import MyUpload from "../components/MyUpload";
import MyFoot from "../components/MyFoot";
import MyFocus from "../components/MyFocus";
import MyCollection from "../components/MyCollection";
import MyMoney from "../components/MyMoney";
import MyRecords from "../components/MyRecords";
import OpenVip from "../components/OpenVip";
import ItemDetail from "../views/ItemDetail";
import UploadEmpty from "../views/upload/UploadEmpty";
import UploadProduction from "../views/upload/UploadProduction";
import WxView from "@/components/WxView";
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/hot_tab',
        name: 'HotTab',
        component: HotTab
    },
    {
        path: '/material',
        name: 'Material',
        component: Material
    },
    {
        path: '/designer',
        name: 'Designer',
        component: Designer
    },
    {
        path: "/message",
        name: 'Message',
        component: Message
    },
    {
      path: '/bottomItemView',
        name: 'BottomItemView',
        component: BottomItemView
    },
    {
        path: '/me',
        name: 'Me',
        component: Me,
        redirect: '/me/detail',
        children: [
            {
                path: 'detail',
                name: 'Detail',
                component: MyDetail
            },
            {
                path: 'vip',
                name: 'Vip',
                component: MyVip
            },
            {
                path: 'download',
                name: 'Download',
                component: MyDownload
            },
            {
                path: 'upload',
                name: 'Upload',
                component: MyUpload,
                redirect: '/me/upload/empty',
                children: [
                    {
                        path: 'empty',
                        name: 'Empty',
                        component: UploadEmpty
                    },
                    {
                        path: 'product',
                        name: 'Product',
                        component: UploadProduction
                    }
                    // {
                    //     path: 'my_product_list',
                    //     name: 'myProductList',
                    //     component: myProductList
                    // }
                ]
            },
            {
                path: 'foot',
                name: 'Foot',
                component: MyFoot
            },
            {
                path: 'focus',
                name: 'Focus',
                component: MyFocus
            },
            {
                path: 'collection',
                name: 'Collection',
                component: MyCollection
            },
            {
                path: 'money',
                name: 'Money',
                component: MyMoney
            },
            {
                path: 'records',
                name: 'Records',
                component: MyRecords
            }
        ]
    },
    {
        path: '/wx',
        name: 'Wx',
        component: WxView
    },
    {
        path: '/open_vip',
        name: 'OpenVip',
        component: OpenVip
    },
    {
        path: '/item_detail',
        name: 'ItemDetail',
        component: ItemDetail
    }
];

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
});

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }


export default router
