<template>
  <div></div>
</template>

<script>
export default {
  name: "WxView",
  data() {
    return {}
  },
  mounted() {
    let code = this.$route.query.code;
    let path = this.$route.query.path;
    console.log("接受到的path：",path);
    this.getUserInfo(code)
    console.log("跳转path:",path)
    this.$router.push({
      path: path,
    })
  },
  methods: {
    getUserInfo(code) {
      let parameter = {
        code: code
      };
      this.$get('/webapi/login/user_info', parameter).then(resp => {
        if (resp.code === 1) {
          if (resp.data.isBinding) {
            //绑定过了,不需要绑定了
            localStorage.setItem('token', resp.data.token);
            this.$store.commit("wxShow", false);
            this.$store.commit('refresh',true)
          } else {
            //需要绑定手机号
            localStorage.setItem('unionid',resp.data.unionid);
            this.$store.commit("wxShow", false);
            this.$store.commit("phoneShow", true)
          }
        } else {
          this.$message.error(resp.message);
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => {

      })
    }
  }
}
</script>

<style scoped>

</style>