<template>
    <div class="detail-out-style">
        <div class="detail-container-style">
            <div class="detail-left-style">
                <div class="detail-left-top-style">
                    <div class="detail-left-top-img-style">
                        <img src="../assets/business.png" alt="bussion">
                    </div>
                    <div>
                        {{itemObj.title}}
                    </div>
                </div>
                <div class="detail-left-line-style"></div>
                <div v-if="!stringIsEmpty(itemObj.desc_video)" class="detail-left-video-style">
                    <video :src="itemObj.desc_video" autoplay controls x5-playsinline playsinline
                           webkit-playsinline="true"></video>
                </div>
                <div class="detail-left-content-container-style">
                    <img class="detail-image" :src="itemObj.desc_image" alt="img"/>
                </div>
            </div>

            <div class="detail-right-style">
                <div class="detail-right-top-style">
                    <div>
                        <div class="detail-right-top-btn-style" @click="getQRCodeUrl">
                            <div>立即购买</div>
                            <img src="../assets/download.png" alt="down">
                        </div>
                        <div @click="collectClick" class="detail-right-top-collect-style">
                            <img v-if="!(itemObj.is_collect === 1)" src="../assets/collect.png" alt="collect">
                            <img v-else src="../assets/star.png" alt="collect">
                        </div>
                    </div>
                    <div>
                        <div>收费价格</div>
                        <div>
                            <div>￥</div>
                            <div>{{getPrice(itemObj)}}</div>
                        </div>
                    </div>
                </div>
                <div class="detail-right-line-style"></div>
                <DetailItem type="1" title="作品编号" :content="itemObj.sn"/>

                <DetailItem type="1" title="文件格式" :content="getFileExt(itemObj)"/>


                <DetailItem type="1" title="作品参数" :content="itemObj.proportion"/>
                <DetailItem type="1" title="发布时间" :content="itemObj.release_at"/>


                <DetailItem type="2" title="作品标签" :content="tags"/>


                <DetailItem type="1" title="作品简介" :content="itemObj.desc"/>


                <div class="detail-right-line-style"></div>

                    <DetailItem type="1" title="版权所有" :content="getCopyRight(itemObj.origin)"/>


                <div class="author-line">
                    <!--origin 字段是素材上传来源 1=设计师上传 3=翰林阁上传 5=粉笔上传-->

                    <div class="author-left">
                        <span class="detail-title-style">设计师</span>
                        <!--服务器返回的itemObj.member可能是null itemObj.origin = 5是粉笔课堂,不显示,-->
                        <span class="author-name">{{getAuthor(itemObj)}}</span>
                    </div>

                    <div v-if="itemObj.origin === 1">
                        <span v-if="itemObj.is_follow === 1" @click="attentionClick" class="force-yes force-btn">已关注</span>
                        <div v-else class="force-no force-btn" @click="attentionClick">关注</div>
                    </div>
                </div>

                <DetailItem type="1" title="字体版权" content="字体仅供参考"/>
                <DetailItem type="1" title="图片版权" content="摄影图片仅供参考"/>
                <DetailItem type="1" title="音频版权" content="背景音乐仅供参考"/>


                <div class="detail-right-line-style"></div>
                <div class="detail-right-copy-style">
                    <div>版权声明</div>
                    <div v-if="itemObj.origin === 5">
                        北京粉笔蓝天科技有限公司将对作品进行维权，按照会员费用乘以传播下载次数的十倍进行索取赔偿金!<br>
                        1.北京粉笔蓝天科技有限公司出售的PPT模板是免版税全(RF:Royalty-free)正版受《中华人民共和国著作法》和《世界版权公约》的保护。作品的所有权、版权和著作权归北京粉笔蓝天科技有限公司所有，您下载的是PPT模板素材使用权。<br>
                        2.不得将北京粉笔蓝天科技有限公司的PPT模板、PPT素材本身用于再出售、或者出租、出借、转让、分销、发布或者作为礼物供他人使用，不得转授权、出卖、转让本协议或本协议中的权利。<br>
                        3.禁止把作品纳入商标或服务标记。<br>
                        4.禁止用户用下载格式在网上传播作品，或者作品可以让第三方单独付费或共享付费下载、或通过转移电话服务系统传播。）<br>
                    </div>
                    <div v-else>
                        感谢您下载翰林阁PPT素材资源库上提供的素材模板！翰林阁所有原创作品（含预览图）均受著作权法保护。<br>
                        著作权、版权及相关权利归翰林阁及签约合作设计师所有或者共有，未经许可任何人不得擅自使用，否则将依法要求承担高达人民币50万元的赔偿责任。<br>
                        翰林阁PPT资源库出售的PPT模板是免版税全(RF:Royalty-free)正版受《中华人民共和国著作法》和《世界版权公约》的保护。作品的所有权、版权和著作权归归翰林阁及签约合作设计师所有或者共有，您下载的是PPT模板素材使用权。<br>
                        不得将翰林阁PPT资源库出售的PPT模板、PPT素材等用于再出售、或者出租、出借、转让、分销、发布或者作为礼物供他人使用，不得转授权、出卖、转让本协议或本协议中的权利。<br>
                        3.禁止把作品纳入商标或服务标记。<br>
                        4.禁止用户用下载格式在网上传播作品，或者作品可以让第三方单独付费或共享付费下载、或通过转移电话服务系统传播。）<br>

                        该模板内容仅供参考，您可以结合自身需求，在修改更新模板内容后投入使用。有任何问题请随时联系网站客服沟通处理。<br>

                        更多精品视频模板：www.hanlinge.cn<br>

                        温馨提示：<br>
                        模板中使用的摄影图均来源于“翰林阁签约设计师专属摄影图图库”，仅供翰林阁设计师作为案例使用，勿作他用。<br>
                    </div>

                </div>
                <div class="detail-right-line-style"></div>

                <!--<div class="detail-right-code-style">-->
                    <!--<img src="../assets/code.png" alt="code">-->
                    <!--<div class="detail-right-code-title-style">-->
                        <!--<div class="detail-right-line-style"></div>-->
                        <!--<div class="code-style">扫一扫二维码</div>-->
                    <!--</div>-->
                <!--</div>-->
            </div>
        </div>

        <!--<div class="detail-bottom-style">-->
            <!--<ResourceItem :itemContent="item"/>-->
        <!--</div>-->

        <el-dialog width="70%" :visible.sync="payDialogVisible" :key="timer">
            <QRCodePay ref="codepay" @dismissSelf="dismissSelf" v-bind:payObj="payObj"></QRCodePay>
        </el-dialog>



    </div>
</template>

<script>
    import ResourceItem from "../components/ResourceItem";
    import DetailItem from "../components/DetailItem";
    import QRCodePay from "../components/QRCodePay"


    export default {
        name: "Detail",
        components: {DetailItem, ResourceItem,QRCodePay},



    watch:{
        payDialogVisible(val, oldVal)
        {//普通的watch监听
            console.log(val)
           if(val){
               //出现时,开始轮询
               // console.log(this.$refs.codepay);
               // this.$refs.codepay.checkPayResult();
           }else{
               this.$refs.codepay.stopCheckPayResult();
           }

        }


    },
        data() {
            return {
                timer: new Date().getTime(),//为了强制刷新弹框

                payDialogVisible:false,
                // author:'',
                material_id:'',
                //测试
                // material_id: 1,

                // isCollected: false,

                haveForce: false,//是否已关注

                payObj:{},
                itemObj: {},
                tags:[], //作品标签

                videoUrl: 'https://vod.pipi.cn/43903a81vodtransgzp1251246104/bbd4f07a5285890808066187974/v.f42906.mp4',
                imgUrl: 'https://preview.qiantucdn.com/58pic/39/74/69/50758PICWf6p464BY7eNr_PIC2018.jpg!w1024_new_small',
                item: {
                    name: 'PPT模板推荐', eName: 'PPT template recommendation'
                },
                product: [
                    {name: '作品编号', content: '19485089', type: '1'},
                    {name: '文件格式', content: 'PPTX', type: '1'},
                    {name: '文件大小', content: '23.5M', type: '1'},
                    {name: '作品比例', content: '16：9', type: '1'},
                    {name: '发布时间', content: '2020-4-26', type: '1'},
                    {
                        name: '作品标签', content: [
                            '简约风',
                            '小清新',
                            '蓝色'
                        ], type: '2'
                    },
                    {
                        name: '作品简介',
                        content: '这是一张关于简约年终总结的PPT模板，简约风格设计，以蓝色为主色，包含公司简介、产品介绍等主题内容，也可用作公司简介PPT、产品PPT。',
                        type: '1'
                    },
                ],
                copyright: [
                    {name: '版权所有', content: '粉笔办公课室', type: '1'},
                    {name: '字体版权', content: '字体仅供参考', type: '1'},
                    {name: '图片版权', content: '摄影图片仅供参考', type: '1'},
                    {name: '音频版权', content: '背景音乐仅供参考', type: '1'},
                ]
            }
        },
        mounted() {
            console.log(this.$route.query);
            this.material_id = this.$route.query.id;

            console.log(this.material_id);


            document.body.scrollTop = document.documentElement.scrollTop = 0;
            this.loadDetail();


        },
        methods: {
            //弹框通知父组件,移除弹框
            dismissSelf(){
                this.payDialogVisible = false;
                console.log("dismissSelf");
            },
            //获取支付二维码
            getQRCodeUrl(){
                let url = "/webapi/order/get_pay_qrcode";
                let parameter = {"material_id":this.material_id};

                this.$post(url,parameter).then(resp=>{
                  console.log(resp.data)

                    this.payObj = resp.data;

                    //测试
                    // this.payObj = {
                    //     source_file: {
                    //         file_url: "",
                    //         price: "1.00",
                    //         qrcode: "https://img2.baidu.com/it/u=1514002029,2035215441&fm=26&fmt=auto",
                    //         title: "简约风白色财务分析PPT模板（素材源文件单价）"
                    //     },
                    //     file:{
                    //         file_url: "",
                    //         price: "2.00",
                    //         qrcode: "https://img2.baidu.com/it/u=1514002029,2035215441&fm=26&fmt=auto",
                    //         title: "简约风白色财务分析PPT模板（素材源文件单价）"
                    //     }
                    // };

                    this.payDialogVisible = true;
                    this.timer = new Date().getTime();

                });


            },

            getCopyRight(origin){

                switch (origin){
                    case 1: return '翰林阁版权所有';
                    case 3: return '翰林阁版权所有';
                    case 5: return '粉笔蓝天版权所有';
                }
            },
            getAuthor(item) {
                if (item.origin === 1) {
                    console.log(item.member);
                    return item.member.nickname;
                } else if (item.origin === 3) {
                    return "翰林阁或签约设计师";
                } else if (item.origin === 5) {
                    return "粉笔办公";
                }
            },


            getPrice(item) {
                if (!this.stringIsEmpty(item.file_ext)) {
                    return item.file_price;
                } else {
                    return item.source_file_price;
                }
            },
            getFileExt(item) {
                if (!this.stringIsEmpty(item.file_ext)) {
                    return item.file_ext;
                } else {
                    return item.source_file_ext;
                }
            },

            loadDetail() {

                let userInfo = JSON.parse(localStorage.getItem("userInfo"));

                let member_id0;
                let parameter = {
                    id: this.material_id
                };


                if(userInfo != null){
                    member_id0 = userInfo.id;
                    parameter.member_id = member_id0;
                }
                let url = '/webapi/material/detail';
                this.$get(url, parameter).then(resp => {
                    this.itemObj = resp.data;
                    this.tags = [...this.itemObj.material_industry_list,...this.itemObj.material_style_list,...this.itemObj.material_color_list];
                })
            },


            //关注
            attentionClick() {
                let status = 0;
                if (this.itemObj.is_follow === 1) {
                    status = 0;//取消收藏
                } else {
                    status = 1//加入收藏
                }

                let url = '/webapi/member/follow';
                let params = {
                    befollow_member_id: this.itemObj.member_id,
                    status: status
                };
                this.$post(url, params).then(resp => {
                    if (resp.code === 1) {
                        this.itemObj.is_follow = (status === 1) ? 1 : 0;
                    }
                })
            },


            //点击收藏按钮
            collectClick() {
                let status = 0;
                if (this.itemObj.is_collect === 1) {
                    status = 0;//取消收藏
                } else {
                    status = 1//加入收藏
                }

                let url = '/webapi/member/collect';
                let params = {
                    material_id: this.material_id,
                    status: status
                };
                this.$post(url, params).then(resp => {
                    if (resp.code === 1) {
                        this.itemObj.is_collect = (status === 1) ? 1 : 0;
                    }
                })
            }


        }
    }
</script>

<style scoped>
    .author-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }

    .author-name {
        font-size: 14px;
        color: #0084FF;
        margin-left: 40px;
    }

    .author-left {
        display: inline-block;
        float: left;
    }

    .force-btn {
        width: 70px;
        height: 30px;
        border-radius: 15px;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        line-height: 30px;
        font-size: 12px;
    }

    .force-yes {
        background-color: #F2F3F3;
        color: #d2c3c8;
    }

    .force-no {
        background: linear-gradient(-90deg, #27BAFA, #4253E3);
        color: white;

    }

    .detail-title-style {
        font-size: 14px;
        font-weight: 500;
        color: #BBBBBB;
    }

    .detail-out-style {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .detail-container-style {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .detail-left-style {
        width: 802px;
        padding-left: 30px;
        padding-right: 30px;
        background: #FFFFFF;
        box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
        border-radius: 10px;
    }

    .detail-right-style {
        width: 270px;
        background: #FFFFFF;
        box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    .detail-bottom-style {
        height: 600px;
    }

    .detail-left-top-style {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
    }

    .detail-left-top-style > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .detail-left-top-style > div > img {
        width: 40px;
        height: 40px;
    }

    .detail-left-top-style > div:nth-child(2) {
        margin-left: 10px;
        font-size: 22px;
        font-weight: bold;
        color: #3C434D;
        line-height: 30px;
    }

    .detail-left-line-style {
        height: 1px;
        background: #F4F4F4;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .detail-left-video-style > video {
        width: 100%;
    }

    .detail-left-content-container-style {
        background: #EBEBEB;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
      align-items: center;
    }
    .detail-image{
      object-fit: fill;
      width: 100%;
    }

    .detail-right-top-style {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .detail-right-top-style > div:first-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .detail-right-top-btn-style {
        width: 212px;
        height: 48px;
        background: linear-gradient(-90deg, #27BAFA, #4253E3);
        border-radius: 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .detail-right-top-btn-style > div {
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
    }

    .detail-right-top-btn-style > img {
        width: 22px;
        height: 22px;
        margin-left: 10px;
    }

    .detail-right-top-collect-style {
        margin-left: 10px;
        width: 48px;
        height: 48px;
        background: rgba(0, 0, 0, 0);
        border: 1px solid #EEEEEE;
        border-radius: 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .detail-right-top-collect-style > img {
        width: 24px;
        height: 24px;
    }

    .detail-right-top-style > div:last-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        width: 100%;
        margin-top: 20px;
    }

    .detail-right-top-style > div:last-child > div:first-child {
        font-size: 14px;
        font-weight: 500;
        color: #BBBBBB;
    }

    .detail-right-top-style > div:last-child > div:last-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        font-weight: bold;
        color: #0084FF;
    }

    .detail-right-top-style > div:last-child > div:last-child > div:first-child {
        font-size: 16px;
    }

    .detail-right-top-style > div:last-child > div:last-child > div:last-child {
        font-size: 30px;
    }

    .detail-right-line-style {
        height: 1px;
        margin-top: 10px;
        margin-bottom: 10px;
        background: #F3F3F3;
    }

    .detail-right-copy-style {
        font-size: 14px;
        font-weight: 500;
        color: #BBBBBB;
    }

    .detail-right-copy-style > div:last-child {
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        margin-top: 7px;
    }

    .detail-right-code-style {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .detail-right-code-style > img {
        width: 130px;
        height: 130px;
    }

    .detail-right-code-title-style {
        position: relative;
        width: 270px;
        margin-top: 5px;
    }

    .code-style {
        position: absolute;
        top: 0;
        left: 32%;
        font-size: 14px;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: 500;
        color: #BBBBBB;
        background: white;
    }

    @media screen and (max-width: 1460px) and (min-width: 1220px) {
        .detail-right-style {
            /*height: 1400px;*/
        }

    }

    @media screen and (max-width: 1220px) {
        .detail-right-style {
            /*height: 1700px;*/
        }

    }
</style>
