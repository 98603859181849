<template>
<!--    <div class="content-container-style">-->
<!--        <div v-for="(item,index) in content" :key="index" class="content-item-style" @click="navigateToDetail(item)">-->
<!--          <div class="one-item">-->
<!--            <img class="preview-img" :src="item.preview_image" alt="url">-->
<!--            <div class="title-line">-->
<!--              <span>{{item.title}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--  </div>-->
<!--  </div>-->
          <div class="resource-img-container-style">
            <div style="margin: 20px" v-for="(item,index) in content" :key="index"  @click="navigateToDetail(item)">
              <img class="resource-img-style" :src="item.preview_image" alt="资源图片">
              <div class="resource-title-container-style"
                   style="display: flex;flex-direction: row;justify-content: space-between;align-items: center">
                <div class="resource-title-style">{{ item.title }}</div>
                <div style="display: flex;flex-direction: row">
                  <!--会-->
                  <img v-if="item.origin === 5" src="../assets/vip.png" alt="vip" class="resource-icon-style">
                  <!--优-->
                  <img v-if="item.is_pay === 1" src="../assets/good.png" alt="good" class="resource-icon-style">
                  <!--商-->
                  <img v-if="item.is_bus === 1" src="../assets/business.png" alt="business" class="resource-icon-style">
                </div>
              </div>
            </div>
          </div>




</template>

<script>
    export default {
        name: "TabContent",
        props: ['content'],
        methods: {
            navigateToDetail(item) {
              this.$router.push({ path:'/item_detail', query:{ id:item.id} })
            }
        }
    }
</script>

<style scoped>
.resource-img-container-style {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 76px;
  cursor: pointer;
}

.resource-img-style {
  width: 320px;
  height: 180px;
  border-radius: 10px;
  object-fit: contain;
  background-color: #f2f2f2;
  /*object-fit: cover;*/
}

.resource-title-container-style {
  margin-top: 7px;
  margin-bottom: 27px;
  width: 320px;

}

.resource-title-style {
  font-size: 14px;
  font-weight: bold;
  color: #3C434D;
  width: 70%;

}

.resource-icon-style {
  width: 30px;
  height: 30px;
}

</style>
