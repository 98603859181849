<template>
    <div class="material-container-style">
        <div class="material-title-style">
            <div>定制素材</div>
            <div>I want material</div>
        </div>
        <!--<div class="material-content-style">-->
        <!--<div>留言板：</div>-->
        <!--<div><textarea placeholder="例：需要一套互联网行业科技风的PPT模板，主色为蓝色。"/></div>-->
        <!--<div>提交留言</div>-->
        <!--</div>-->

        <div style="font-size: 12px;color: #999;padding: 30px 17% 0 23%">
            <span v-html="des"></span>
        </div>

        <div style="margin-top: 10px">
            <span class="title-item">
                 <span class="red-star">*</span>
                <span>文件类型</span>
            </span>
            <el-select v-model="formData.selectType" class="select-item" placeholder="请选择文件类型">
                <el-option
                        v-for="item in type"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>

        <div>
            <span class="title-item">
                 <span class="red-star">*</span>
                <span>风格</span>
            </span>
            <el-select multiple v-model="formData.selectStyle" class="select-item" placeholder="请选择风格">
                <el-option
                        v-for="item in style"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>

        <div>
            <span class="title-item">
                 <span class="red-star">*</span>
                <span>颜色</span>
            </span>
            <el-select multiple v-model="formData.selectColor" class="select-item" placeholder="请选择颜色">
                <el-option
                        v-for="item in color"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>

        <div>
            <span class="title-item">
                 <span class="red-star">*</span>
                <span>行业</span>
            </span>
            <el-select multiple v-model="formData.selectIndustry" class="select-item" placeholder="请选择行业">
                <el-option
                        v-for="item in industry"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>

        <div style="display: flex; justify-content: flex-start; align-items: flex-start">
            <span class="title-item">
                 <span class="red-star">*</span>
                <span>用途</span>
            </span>

            <el-input class="use-input"   :placeholder="placeholder"
                        type="textarea" v-model="formData.use"></el-input>

        </div>

        <div>
            <span class="title-item" style="margin-top: 35px">
                 <!--<span class="red-star">*</span>-->
                <span>分辨率</span>
            </span>
            <el-input v-model="formData.resolution" class="select-item" placeholder="请输入分辨率">
            </el-input>
        </div>

        <div>
            <span class="title-item">
                 <span class="red-star">*</span>
                <span>联系方式</span>
            </span>
            <el-input v-model="formData.contact" class="select-item" placeholder="请输入联系方式">
            </el-input>
        </div>

        <div>
            <span class="title-item">
                 <span class="red-star">*</span>
                <span>验收时间</span>
            </span>
            <el-select v-model="formData.checkTime" class="select-item" placeholder="请选择验收时间">
                <el-option
                        v-for="item in times"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>



        <div class="material-toast-style">
            <img src="../assets/toast.png" alt="toast">
            <div>您的需求已提交</div>
        </div>



        <div class="detail-save-style" @click="submitClick">
            提交留言
        </div>



    </div>
</template>

<script>
    export default {

        name: "Material",
        data() {
            return {
                des:"\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0在这里可以写出您的需求及想法，我们会有专业的设计师为您提供专业定制服务，严格把控专业设计流程，保证素材质量及项目时间。为了更好地给您提供服务，请认真填写一下内容。",
                placeholder:'例 ：大型展会、工作汇报、新闻发布会中使用',
                formData: {
                    selectType: null,//类型
                    selectIndustry: [],//行业
                    selectStyle: [],//风格
                    selectColor: [],//颜色
                    use:"",//用途
                    resolution:"",//分辨率
                    contact:"",//联系方式
                    checkTime:1,//验收时间
                },
                times:[{id:1,name:"三天内"},{id:2,name:"一周内"},{id:3,name:"一月内"},{id:4,name:"不着急/要质量"}],
                type: [],
                industry: [],
                style: [],
                color: [],
            }
        },
        mounted() {
            this.getData();
        },

        methods: {
            submitClick(){
              let res =  this.checkParamater();
              if(res){
                  let url = "/webapi/home/client_needs";
                  let parameter = {
                      member_id:'',
                      type:this.formData.selectType,
                      material_industry:this.formData.selectIndustry.join(','),
                      material_style:this.formData.selectStyle.join(','),
                      material_color:this.formData.selectColor.join(','),
                      phone:this.formData.contact,
                      resolution:this.formData.resolution,
                      intent:this.formData.use,
                      time:this.formData.checkTime
                  };
                  this.$post(url,parameter).then(resp=>{
                      if(resp.code === 1){
                        this.$message.success("提交成功");
                          this.formData = {
                              selectType: null,//类型
                                  selectIndustry: [],//行业
                                  selectStyle: [],//风格
                                  selectColor: [],//颜色
                                  use:"",//用途
                                  resolution:"",//分辨率
                                  contact:"",//联系方式
                          };

                      }else{
                        this.$message.error(resp.msg);
                      }
                  })
              }
            },

            checkParamater(){
                if(this.stringIsEmpty(this.formData.selectType )){
                    this.$message.warning("请选择类型");return false;
                }else if(this.formData.selectIndustry.length === 0){
                    this.$message.warning("请选择行业");return false;
                }else if(this.formData.selectStyle.length === 0){
                    this.$message.warning("请选择风格");return false;
                }else if(this.formData.selectColor.length === 0){
                    this.$message.warning("请选择颜色");return false;
                }else if(this.stringIsEmpty(this.formData.use )){
                    this.$message.warning("请填写用途");return false;
                }else if(this.stringIsEmpty(this.formData.contact )){
                    this.$message.warning("请填写联系方式");return false;
                }
                return true;
            },

            getData() {
                // /webapi/material/tabs
                this.$get('/webapi/material/select_tab').then(resp => {
                    console.log(resp);
                    this.type = resp.data.type;
                    // this.formData.selectType = this.type[0].id;

                    this.industry = resp.data.industry;
                    // this.formData.selectIndustry = this.industry[0].id;


                    this.style = resp.data.style;
                    // this.formData.selectStyle = this.style[0].id;

                    this.color = resp.data.color;
                    // this.formData.selectColor = this.color[0].id;

                }).catch(err => {
                    console.log(err);
                })
            }
        }


    }
</script>

<style scoped>
    .detail-save-style {
        margin-top: 10px;
        margin-bottom: 40px;
        width: 270px;
        height: 48px;
        background: linear-gradient(-90deg, #27bafa, #4253e3);
        box-shadow: 0 4px 9px 0 rgba(42, 176, 248, 0.6);
        border-radius: 24px;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }



    .title-item {
        display: inline-flex;
        justify-content: flex-end;
        width: 130px;
        margin: 25px 10px;
        font-size: 18px;
        font-weight: 500;
        color: #333333;

    }

    .red-star {
        color: red;
        font-size: 20px;
    }

    .select-item {
        width: 648px;
        height: 40px;
        background: #F2F3F3;
        border-radius: 5px;
    }

    .use-input{
        width: 648px;
        height: 140px;
        background: #F2F3F3;
        border-radius: 5px;
        margin-top: 20px;
    }

    >>> .el-textarea__inner{
        height: 150px;
        overflow-y: auto;
        /* overflow-y: auto;兼容ie  */
    }



    .material-container-style {
        height: 1165px;
        box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
        background: white;
        width: 100%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .material-title-style {
        width: 100%;
        height: 140px;
        box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .material-title-style > div:first-child {
        color: #3C434D;
        font-size: 18px;
        font-weight: bold;
    }

    .material-title-style > div:nth-child(2) {
        font-size: 16px;
        color: #3C434D;
        opacity: 0.3;
        font-weight: 500;
        margin-top: 8px;
    }

    .material-content-style {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .material-content-style > div {
        width: 64%;
    }

    .material-content-style > div:first-child {
        margin-top: 60px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
    }

    .material-content-style > div:nth-child(2) {
        margin-top: 30px;
        background: #F2F3F3;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 350px;
    }

    .material-content-style > div:nth-child(2) > textarea {
        width: 95%;
        height: 90%;
        background: #F2F3F3;
        border: none;
        outline: none;
        resize: none;
        font-size: 14px;
        font-weight: 500;
        border-radius: 5px;
    }

    .material-content-style > div:nth-child(3) {
        width: 270px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 9px 0 rgba(42, 176, 248, 0.6);
        background: linear-gradient(-90deg, #27BAFA, #4253E3);
        border-radius: 24px;
        color: #FFFFFF;
        margin-top: 60px;
        cursor: pointer;
        font-size: 14px;
    }

    .material-toast-style {
        width: 40%;
        height: 90px;
        box-shadow: 0 0 35px 3px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        visibility: hidden;
    }

    .material-toast-style > img {
        width: 40px;
        height: 40px;
    }

    .material-toast-style > div {
        color: #4253E3;
        font-weight: 500;
        font-size: 20px;
        margin-left: 13px;
    }

    @media screen and (max-width: 1220px) {
        .material-title-style > div:first-child {
            font-size: 16px;
        }

        .material-title-style > div:nth-child(2) {
            font-size: 14px;
        }

        .material-content-style > div:first-child {
            font-size: 14px;
        }

        .material-content-style > div:nth-child(2) > textarea {
            font-size: 12px;
        }

        .material-content-style > div:nth-child(3) {
            font-size: 14px;
        }

        .material-toast-style {
            height: 80px;
        }

        .material-toast-style > img {
            width: 30px;
            height: 30px;
        }

        .material-toast-style > div {
            font-size: 18px;
        }
    }

    @media screen and (min-width: 1460px) {
        .material-title-style > div:first-child {
            font-size: 20px;
        }

        .material-title-style > div:nth-child(2) {
            font-size: 18px;
        }

        .material-content-style > div:first-child {
            font-size: 18px;
        }

        .material-content-style > div:nth-child(2) > textarea {
            font-size: 14px;
        }

        .material-content-style > div:nth-child(3) {
            font-size: 14px;
        }

        .material-toast-style {
            height: 100px;
        }

        .material-toast-style > img {
            width: 50px;
            height: 50px;
        }

        .material-toast-style > div {
            font-size: 25px;
        }
    }
</style>
