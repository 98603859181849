<template>
    <div v-loading="loading">
        <Carousel/>
        <!--<div v-for="(item,index) in items" :key="index">-->
            <!--<ResourceItem :name="'热门资源'" :eName="'Hot resources'" :itemContent="item" v-if="index === 'hot_material'"/>-->
            <!--<ResourceItem :name="'最新素材'" :eName="'Latest material'" :itemContent="item" v-else-if="index === 'new_material'"/>-->
            <!--<ResourceItem :name="'PPT模板推荐'" :eName="'PPT template recommendation'" :itemContent="item" v-else-if="index === 'ppt_material'"/>-->

            <ResourceItem name="热门素材" eName="Hot material" :itemContent="itemObj.hot_material"/>
            <ResourceItem name="最新素材" eName="New material" :itemContent="itemObj.new_material"/>
            <ResourceItem name="PPT模板" eName="PPT template" :itemContent="itemObj.type1"/>
            <ResourceItem name="Excel图表" eName="Excel Chart" :itemContent="itemObj.type2"/>
            <ResourceItem name="免抠" eName="No Deduction" :itemContent="itemObj.type3"/>
            <ResourceItem name="图标" eName="Icon" :itemContent="itemObj.type4"/>
            <ResourceItem name="图片" eName="Picture" :itemContent="itemObj.type5"/>
            <ResourceItem name="3D" eName="3D" :itemContent="itemObj.type6"/>
            <ResourceItem name="视频" eName="Video" :itemContent="itemObj.type7"/>

            <!--<VideoItem :itemIndex="index" :itemContent="itemObj.type7"/>-->

        <!--</div>-->
    </div>
</template>

<script>

    import Carousel from "@/components/Carousel";
    import ResourceItem from "@/components/ResourceItem";
    // import VideoItem from "@/components/VideoItem";

    export default {
        name: 'Home',
        components: { ResourceItem, Carousel},
        data() {
            return {
                itemObj: {},
                loading: false
            }
        },
        mounted() {
            this.getMaterials()
        },
        methods: {
            getMaterials: function () {
                this.loading = true
                this.$get("/webapi/home/material")
                    .then(res => {
                        if (res.code === 1) {
                            this.itemObj = res.data
                        } else {
                            console.log(res.msg)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        }
    }
</script>
<style>

</style>
