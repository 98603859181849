<template>
  <div class="my-card-style" v-loading.sync="loading">
    <div class="detail-title-container">
      <div>个人资料</div>
      <div>Personal data</div>
    </div>
    <div class="detail-line-style"></div>
    <div class="vip-open-container-style" v-if="vip === 0">
      <img src="../assets/empty.png" alt="empty">
      <div class="vip-not-style">您还没有开通会员哦！</div>
      <div class="vip-open-style" @click="navigateToOpenVip">开通会员</div>
    </div>
    <div v-else class="vip-card-out-style">
      <div class="vip-card-container-style">
        <img src="../assets/vip_img.png" alt="vip">
        <div class="vip-card-name-container-style">
          <div>
            <div class="vip-card-name-style">粉笔会员</div>
            <div class="vip-card-id-style">ID:538294012</div>
          </div>
          <div class="vip-card-status-style" v-if="vip === 1">终身</div>
          <div class="vip-card-status-style" v-else><div>会员截止到： </div> {{ vip }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyVip",
  data() {
    return {
      loading: false,
      isOpen: false,
      vip: 0,
    }
  },
  mounted() {
    // this.$parent.changeCss()
    this.getUserInfoByToken();
  },
  methods: {
    navigateToOpenVip: function () {
      this.$router.push({
        name: 'OpenVip'
      })
    },
    getUserInfoByToken() {
      //不需要token,token是放header里面的
      let url = '/webapi/login/user_info_by_token';
      this.loading = true;
      this.$get(url, {}).then(resp => {
        if (resp.code === 1) {
          this.vip = resp.data.vip_status
          if (this.vip != null && this.vip > 1) {
            let d = new Date(this.vip * 1000);    //根据时间戳生成的时间对象
            this.vip = (d.getFullYear()) + "-" +
                (d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" +
                (d.getDate() < 10 ? '0' + d.getDate() : d.getDate());
          }
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  }
}
</script>

<style scoped>
.my-card-style {
  height: 950px;
  background: #FFFFFF;
  box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.detail-title-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding-top: 30px;
  width: 100%;
}

.detail-title-container > div:first-child {
  margin-left: 60px;
  font-size: 22px;
  font-weight: bold;
  color: #3C434D;
}

.detail-title-container > div:last-child {
  font-size: 20px;
  font-weight: 500;
  color: #3C434D;
  opacity: 0.3;
  margin-left: 12px;
}

.detail-line-style {
  margin-top: 30px;
  height: 1px;
  width: 100%;
  background: #F4F4F4;
}

.detail-avatar-style > img {
  width: 120px;
  height: 120px;
}

.detail-container-style > div:first-child {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  width: 120px;
  text-align: right;
}

.detail-container-style > input {
  height: 40px;
  background: #F2F3F3;
  border-radius: 5px;
  border: none;
  outline: none;
  padding-left: 20px;
  margin-left: 30px;
  flex: 1;
  margin-right: 45px;
}

.vip-open-container-style {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.vip-open-container-style > img {
  width: 100px;
  margin-top: 35px;
}

.vip-not-style {
  font-size: 14px;
  font-weight: 500;
  color: #BBBBBB;
  margin-top: 20px;
}

.vip-open-style {
  width: 270px;
  height: 48px;
  background: linear-gradient(-90deg, #27BAFA, #4253E3);
  box-shadow: 0 4px 9px 0 rgba(42, 176, 248, 0.6);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 40px;
  cursor: pointer;
}

.vip-card-out-style {
  width: 100%;
}

.vip-card-container-style {
  margin-top: 30px;
  margin-left: 60px;
  width: 280px;
  height: 150px;
  background: linear-gradient(-90deg, #20DBFE, #75A6FF);
  box-shadow: 0 8px 19px 0 rgba(29, 73, 138, 0.07);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vip-card-container-style > img {
  width: 84px;
  height: 97px;
  margin-left: 20px;
}

.vip-card-name-container-style {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 95px;
  margin-left: 20px;
}

.vip-card-name-style {
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
}

.vip-card-id-style {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}

.vip-card-status-style {
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
}
</style>
