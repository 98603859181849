<template>
    <div class="header-container-style">
        <div class="flex-row-start">
            <img
                    :src="logoImg"
                    class="header-logo-style"
                    alt="logo"
                    @click="navigateToHome"
            />
            <div class="header-search-container">
                <input
                        type="text"
                        class="header-search-style"
                        v-model="searchData"
                        placeholder="输入搜索关键词"
                        v-on:keyup.enter="searchClick"
                />
                <img
                        src="../assets/search.png"
                        class="header-search-img-style"
                        alt="search-img"
                        @click="searchClick"
                />
            </div>
        </div>
        <div class="flex-row-end">
            <div
                    :class="[isWhite ? 'white-color' : 'black-color', 'header-btn-style1']"
                    @click="navigateToHome"
            >
                首页
            </div>
            <!-- <div
                    :class="[isWhite ? 'white-color' : 'black-color', 'header-btn-style2']"
                    @click="navigateToClass"
            >
                粉笔课堂
            </div> -->
            <div
                    :class="[isWhite ? 'white-color' : 'black-color', 'header-btn-style2']"
                    @click="navigateToMaterial"
            >
                定制素材
            </div>
            <div
                    :class="[isWhite ? 'white-color' : 'black-color', 'header-btn-style2']"
                    @click="navigateToDesigner"
            >
                兼职设计师
            </div>
            <div style="position: relative">
                <img
                        :src="msgImg"
                        alt="waining"
                        class="header-message-style"
                        @click="navigateToMessage"
                />
                <div class="red-circle" v-if="isMsg"></div>
            </div>
            <div
                    :class="[isWhite ? 'white-color' : 'black-color', 'header-btn-style3']"
                    @click="navigateToMe"
            >
                {{ name }}
            </div>
            <img
                    :src="avatar"
                    alt="头像"
                    class="el-avatar--circle header-avatar-style"
                    @click="navigateToMe"
            />
        </div>
    </div>
</template>

<script>
    // import mqtt from "mqtt";

    // var client;
    // const options = {
    //     connectTimeout: 40000,
    //     clean: true,
    // };
    // client = mqtt.connect("ws://8.140.141.32:8083/mqtt", options);
    // client = mqtt.connect("wss://8.140.141.32:8084/mqtt", options);
    export default {
        name: "Header",
        data() {
            return {
                searchData: "",
                logoImg: require("../assets/logo_white.png"),
                avatar: require("../assets/avatar.png"),
                msgImg: require("../assets/black_msg.png"),
                isWhite: true,
                name: "请登录",
                topic: "",
                isMsg: false,
            };
        },
        mounted() {
            this.$store.commit("searchData", this.searchData);
            let userInfo = localStorage.getItem("userInfo");
            let user = JSON.parse(userInfo);
            this.avatar = user.headimgurl;
            this.name = user.nickname;
            // this.openMqtt();
            this.loadMessageNum();

        },

        watch: {
            "$store.state.theme"(newVal, oldVal) {
                console.log(oldVal);
                if (newVal) {
                    this.logoImg = require("../assets/logo_white.png");
                    this.msgImg = require("../assets/white_msg.png");
                    this.isWhite = true;
                } else {
                    this.logoImg = require("../assets/logo_black.png");
                    this.msgImg = require("../assets/black_msg.png");
                    this.isWhite = false;
                }
            },
            "$store.state.avatar"(newVal) {
                this.avatar = newVal;
            },
            "$store.state.name"(newVal) {
                this.name = newVal;
                // this.openMqtt();
            },

            "$store.state.refreshMessageNum"(newVal) {
                this.loadMessageNum();
            }

        },

        methods: {
            loadMessageNum() {
                let url = '/webapi/message/new_message';
                this.$get(url).then(resp=>{
                    if(resp.code === 1){
                        console.log("消息数量:");
                        console.log(resp.data.new_message_count);

                        if(resp.data.new_message_count > 0){
                            this.isMsg = true;
                        }else{
                            this.isMsg = false;
                        }
                    }
                })
            },

            // openMqtt() {
            //     //测试 线上不支持ws,暂时注掉
            //     let userInfo = localStorage.getItem("userInfo");
            //     if (userInfo !== null && userInfo !== undefined) {
            //         let user = JSON.parse(userInfo);
            //         if (client.connected) {
            //           console.log(1111111111111111);
            //             let topic = "/notice/" + user.id;
            //             if (
            //                 this.topic !== undefined &&
            //                 this.topic !== "" &&
            //                 this.topic === topic
            //             ) {
            //                 return;
            //             }
            //             if (this.topic !== undefined && this.topic !== "") {
            //                 client.unsubscribe(this.topic);
            //                 this.isMsg = false;
            //             }
            //             this.topic = topic;
            //             client.subscribe(this.topic, {qos: 0}, (error) => {
            //                 if (!error) {
            //                     console.log("订阅成功");
            //                 } else {
            //                     console.log("订阅失败");
            //                 }
            //             });
            //         }else {
            //           console.log(2222222222222);
            //         }
            //         client.on("connect", (e) => {
            //             let topic = "/notice/" + user.id;
            //             if (
            //                 this.topic !== undefined &&
            //                 this.topic !== "" &&
            //                 this.topic === topic
            //             ) {
            //                 return;
            //             }
            //             if (this.topic !== undefined && this.topic !== "") {
            //                 client.unsubscribe(this.topic);
            //                 this.isMsg = false;
            //             }
            //             this.topic = topic;
            //             console.log(this.topic);
            //             client.subscribe(this.topic, {qos: 0}, (error) => {
            //                 if (!error) {
            //                     console.log("订阅成功");
            //                 } else {
            //                     console.log("订阅失败");
            //                 }
            //             });
            //         });
            //         client.on("message", (topic, message) => {
            //             let messageData = JSON.parse(message.toString());
            //             this.$notify({
            //                 title: messageData.title,
            //                 message: messageData.content,
            //                 type: "success",
            //             });
            //             this.isMsg = true;
            //         });
            //     } else {
            //         if (this.topic !== undefined && this.topic !== "") {
            //             client.unsubscribe(this.topic);
            //             this.isMsg = false;
            //         }
            //     }
            // },

            searchClick() {
                if (this.$route.path === "/hot_tab") {
                    // 搜索也搜索素材
                    console.log("搜索");
                    // store.searchData = this.searchData;
                    this.$store.commit("searchData", this.searchData);
                    this.$store.commit("beginSearch");
                } else {
                    this.$router.push({
                        path: "/hot_tab",
                        query: {module: 0, type: 0, industry: 0, style: 0, color: 0, keywords: this.searchData},
                    });
                }
            },

            // navigateToPath: function () {
            //   this.$router.push({
            //     name: 'HotTab'
            //   })
            // },

            navigateToMaterial: function () {
                this.$router.push({
                    name: "Material",
                });
            },
            navigateToDesigner: function () {
                this.$router.push({
                    name: "Designer",
                });
            },
            navigateToHome: function () {
                this.$router.push({
                    name: "Home",
                });
            },
            navigateToMessage: function () {
                this.$router.push({
                    name: "Message",
                });
            },
            navigateToMe: function () {
                this.$router.push({
                    name: "Me",
                });
            },
            navigateToClass: function () {
                window.open("http://www.fboffice.cn/");
            },
        },
    };
</script>

<style scoped>
    .header-container-style {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 41px;
        margin-bottom: 48px;
    }

    .header-search-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 11px;
    }

    .header-search-style {
        border: none;
        outline: none;
        width: 419px;
        height: 40px;
        font-size: 16px;
        border-radius: 23px;
        padding-left: 31px;
    }

    .header-search-img-style {
        position: relative;
        cursor: pointer;
        right: 36px;
        width: 21px;
        height: 21px;
    }

    .header-logo-style {
        width: 140px;
        height: 40px;
        cursor: pointer;
    }

    .header-btn-style1 {
        /*color: white;*/
        font-weight: bold;
        cursor: pointer;
        font-size: 16px;
    }

    .header-btn-style2 {
        /*color: white;*/
        font-weight: bold;
        cursor: pointer;
        font-size: 16px;
        margin-left: 31px;
    }

    .header-message-style {
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin-left: 27px;
    }

    .header-btn-style3 {
        /*color: white;*/
        font-weight: bold;
        cursor: pointer;
        font-size: 16px;
        margin-left: 31px;
    }

    .header-avatar-style {
        background: white;
        cursor: pointer;
        width: 40px;
        height: 40px;
        margin-left: 10px;
        border-radius: 50%;
    }

    .red-circle {
        position: absolute;
        top: 0;
        right: 0;
        background-color: red;
        border-radius: 50%;
        display: inline-block;
        width: 5px;
        height: 5px;
    }
</style>
