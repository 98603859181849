<template>
    <div class="footer-container-style" v-if="isShow">
        <div class="footer-detail-style">
            <div class="footer-link-style1">
                <div class="line-title-style">快捷入口</div>
                <!--<div class="link-style1">首页</div>-->
                <!--<div class="link-style">PPT模板</div>-->
                <!--<div class="link-style">Excel图表</div>-->
                <!--<div class="link-style">免抠</div>-->
                <!--<div class="link-style">图标</div>-->
                <!--<div class="link-style">图片</div>-->
                <!--<div class="link-style">3D</div>-->
                <!--<div class="link-style">视频</div>-->
                <!--<div class="link-style">成为会员</div>-->
                <!--<div class="link-style">加入兼职设计师</div>-->

                <router-link to="/" class="my-link"><div class="link-style1">首页</div></router-link>
                <!--<div @click="goToSearchPage(1)" class="link-style">PPT模板</div>-->
                <!--<div @click="goToSearchPage(2)" class="link-style">Excel图表</div>-->

                <router-link :to="{path:'/hot_tab',query:{type:1}}" class="my-link"><div class="link-style">PPT模板</div></router-link>
                <router-link :to="{path:'/hot_tab',query:{type:2}}" class="my-link"><div class="link-style">Excel图表</div></router-link>
                <router-link :to="{path:'/hot_tab',query:{type:3}}" class="my-link"><div class="link-style">免抠</div></router-link>
                <router-link :to="{path:'/hot_tab',query:{type:4}}" class="my-link"><div class="link-style">图标</div></router-link>
                <router-link :to="{path:'/hot_tab',query:{type:5}}" class="my-link"><div class="link-style">图片</div></router-link>
                <router-link :to="{path:'/hot_tab',query:{type:6}}" class="my-link"><div class="link-style">3D</div></router-link>
                <router-link :to="{path:'/hot_tab',query:{type:7}}" class="my-link"><div class="link-style">视频</div></router-link>
                <router-link :to="{path:'/vip'}" class="my-link"><div class="link-style">成为会员</div></router-link>
                <router-link :to="{path:'/designer'}" class="my-link"><div class="link-style">加入兼职设计师</div></router-link>


            </div>
            <div class="footer-link-style2">
                <div class="line-title-style">关于我们</div>
                <div @click="clickBottomItem(item)" :class="[index===0 ? 'link-style1' : 'link-style']"
                     v-for="(item,index) in aboutUs" :key="index">{{item.label}}
                </div>
            </div>
            <div class="footer-link-style3">
                <div class="line-title-style">相关服务</div>
                <div @click="clickBottomItem(item)" :class="[index===0 ? 'link-style1' : 'link-style']"
                     v-for="(item,index) in aboutServe" :key="index">{{item.label}}
                </div>
            </div>


            <div class="footer-link-style4">
                <div class="line-title-style">关注我们</div>
                <div class="link-style1" style="display: flex;flex-direction: row;">
                    <el-popover
                            placement="bottom"
                            width="120"
                            trigger="hover">
                        <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;width: 100%">
                            <img style="width: 100px;height: 100px" src="../assets/erwei1.jpg" alt="二维码">
                            <div style="margin-top: 10px">粉笔办公学习平台</div>
                        </div>
                        <img slot="reference" class="wechat-img-style1" src="../assets/erwei11.png" alt="粉笔办公学习平台">
                    </el-popover>
                    <el-popover
                            placement="bottom"
                            width="120"
                            trigger="hover">
                        <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;width: 100%">
                            <img style="width: 100px;height: 100px" src="../assets/erwei2.jpg" alt="二维码">
                            <div style="margin-top: 10px">谦视觉订阅号</div>
                        </div>
                        <img slot="reference" class="wechat-img-style1" src="../assets/erwei22.png" alt="谦视觉订阅号">
                    </el-popover>
                    <el-popover
                            placement="bottom"
                            width="120"
                            trigger="hover">
                        <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;width: 100%">
                          <img style="width: 100px;height: 100px" src="../assets/erwei4.jpg" alt="二维码">
                          <div style="margin-top: 10px">翰林阁客服微信</div>
                        </div>
                        <img slot="reference" class="wechat-img-style1" src="../assets/erwei33.png" alt="翰林阁客服微信">
                    </el-popover>

                    <el-popover
                            placement="bottom"
                            width="120"
                            trigger="hover">
                        <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;width: 100%">
                          <img style="width: 100px;height: 100px" src="../assets/erwei3.jpg" alt="二维码">
                          <div style="margin-top: 10px">粉笔办公官微</div>
                        </div>
                        <img slot="reference" class="wechat-img-style1" src="../assets/erwei44.png" alt="粉笔办公官微">
                    </el-popover>

                </div>
                <div>
                    <img class="footer-code-style" :src="codeUrl" alt="二维码" v-if="isShowCode">
                </div>
            </div>
            <div>
                <div class="line-title-style">联系我们</div>
                <div class="link-style3">电话：010-52429206</div>
                <div class="link-style2">商务合作微信：zheng_xueqian</div>
                <div class="link-style2">官方邮箱：npmgadmin@126.com</div>

                <div class="link-style2">客服微信：zheng_xueqian</div>
                <div class="link-style2">设计师咨询：D17560676145</div>
                <div class="link-style2">地址：北京市朝阳区望京街2<br>
                    号合生麒麟社2号楼
                </div>
            </div>
        </div>
        <div class="line-style"></div>
        <div class="friend-link-container-style">
            <div class="friend-link-title-style">友情链接：</div>
            <div class="friend-link-style">
                <a v-for="(item,index) in links" :key="index" :href="item.site_link" target="_blank">
                    {{item.site_name}}
                </a>
            </div>
        </div>
        <div class="line-style"></div>
        <div class="footer-bottom-container-style">
            <div class="footer-bottom-name-style">欢迎来到hanlinge.cn</div>
            <div class="footer-bottom-detail-style">网站备案号:<a class="beian" href="https://beian.miit.gov.cn/" target="_blank"> 京ICP备19035832号-2 </a ></div>
            <img v-if="isShowToTop" @click="toPageTop" class="to-top-img" src="../assets/toTop.png" alt="" width="40" height="40">
        </div>

        <!--通过:key重新渲染子组件-->
        <!--<BottomItemView :key="itemId" @closeDialog="closeDialog" :showBottomItemDialog="showBottomItemDialog" :id="itemId"></BottomItemView>-->
    </div>
</template>

<script>
    // import BottomItemView from '../components/BottomItemView'


    export default {
        name: "Footer",

        components:{
            // BottomItemView
        },


        data() {
            return {
                isShowToTop:false,

                showBottomItemDialog:false,//点击底部的项目,对话框
                itemId:0,


                aboutUs: [
                    {
                        label: '关于我们',
                        id: 101,
                        english:'About us\n'
                    },
                    {
                        label: '翰林阁平台服务用户使用协议',
                        id: 102,
                        english:'Hanlin Court Platform Service User Agreement'
                    },
                    {
                        label: '纳德睿合隐私保护政策',
                        id: 103,
                        english:'Nade Ruihe Privacy Policy'
                    },
                    {
                        label: '隐私条款',
                        id: 104,
                        english:'Privacy policy'
                    },

                    {
                        label: '翰林阁平台数字作品上传发布使用协议',
                        id: 105,
                        english:'Agreement for the Upload and Release of Digital Works on the Hanlin Pavilion Platform'
                    },
                    {
                        label: '合作授权书',
                        id: 106,
                        english:'Cooperation authorization\n'
                    },
                    {
                        label: '上传问题指南',
                        id: 107,
                        english:'Upload problem guide\n'
                    },
                    {
                        label: '会员问题指南',
                        id: 108,
                        english:'Member Questions Guide\n'
                    },
                    {
                        label: '版权声明书',
                        id: 109,
                        english:'Copyright statement'
                    },

                ],

                aboutServe: [
                    {
                        label: 'PPT制作服务',
                        id: 201,
                        english:'PPT production service'
                    },
                    {
                        label: 'Excel数据分析',
                        id: 202,
                        english:'Excel data analysis'
                    },
                    {
                        label: 'Excel工具开发',
                        id: 203,
                        english:'Excel tool development'
                    },
                  {
                    label: 'Excel智能插件',
                    id: 206,
                    english:'Excel smart plug-in'
                  },

                    {
                        label: 'Word工具开发',
                        id: 204,
                        english:'Word tool development'
                    },
                    {
                        label: 'AE&PR视频制作',
                        id: 205,
                        english:'AE&PR video production'
                    },
                ],

                codeUrl: require('../assets/code.png'),
                isShowCode: false,
                links: [],
                isShow: true
            }
        },
        mounted() {
            this.getLinks();
            //监听滚动
            window.addEventListener("scroll",this.handleScroll);

        },
        methods: {

            handleScroll() {
                this.isShowToTop = window.pageYOffset > 10
            },
                toPageTop(){
                window.scrollTo(0, 0)
            },

            clickBottomItem(item){

                // this.showBottomItemDialog = true;
                // this.itemId = item.id;
                // this.dialogTitle = item.label;

                let params = {
                    id:item.id,
                    title:item.label,
                    subTitle:item.english
                };

                // this.$router.push({name:'BottomItemView',params:params})

                this.$router.push({path:'/bottomItemView',query:params})
            },
            closeDialog(){
                this.showBottomItemDialog = false;
            },


            getLinks: function () {
                this.$get("/webapi/home/partner_site")
                    .then(res => {
                        if (res.code === 1) {
                            this.links = res.data
                        } else {
                            console.log(res.msg)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {

                    })
            }
        },
        watch: {
            $route(to,from) {
                if(to.path == "/bottomItemView") {
                    this.isShow = false
                }else{
                    this.isShow = true
                }
            }
        }
    }
</script>

<style scoped>
    .footer-container-style {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 100px auto 0;
        box-shadow: 0 -10px 21px 2px rgba(81, 130, 255, 0.1);
        background: white;
        width: 100%;
    }

    .footer-detail-style {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 67px;
        width: 1520px;
    }

    .link-style {
        font-size: 18px;
        color: #999999;
        margin-top: 20px;
        cursor: pointer;
    }
    .my-link{
        text-decoration: none;
    }

    .link-style1 {
        color: #999999;
        font-size: 18px;
        margin-top: 49px;
        cursor: pointer;
        text-decoration: none;
    }

    .link-style2 {
        font-size: 18px;
        color: #999999;
        margin-top: 20px;
    }

    .link-style3 {
        color: #999999;
        font-size: 18px;
        margin-top: 49px;
    }

    .wechat-img-style {
        width: 30px;
        height: 30px;
        padding: 10px;
        background: white;
        border-radius: 50%;
        box-shadow: 0 0 18px 2px rgba(81, 130, 255, 0.2);
    }

    .wechat-img-style1 {
        width: 30px;
        height: 30px;
        padding: 0px;
        background: white;
        border-radius: 50%;
        box-shadow: 0 0 18px 2px rgba(81, 130, 255, 0.2);
        margin-left: 20px;
    }

    .line-title-style {
        font-size: 22px;
        color: #333333;
        font-weight: 500;
    }



    .line-style {
        width: 1520px;
        height: 1px;
        background: #E4E4E4;
        margin-top: 57px;
    }

    .friend-link-container-style {
        width: 1520px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 59px;
    }

    .friend-link-title-style {
        color: #333333;
        font-size: 22px;
        font-weight: 500;
        white-space: nowrap
    }

    .friend-link-style {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin-left: 22px;
    }

    .friend-link-style a {
        color: #666666;
        font-size: 18px;
        font-weight: 500;
        margin-right: 15px;
        margin-bottom: 10px;
        cursor: pointer;
        text-decoration: none
    }

    .footer-bottom-container-style {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #4253E3;
    }

    .footer-bottom-name-style {
        margin-top: 70px;
        font-size: 24px;
    }

    .footer-bottom-detail-style {
        margin-top: 21px;
        margin-bottom: 57px;
        font-size: 20px;
    }

    .to-top-img{
        position: fixed;
        bottom: 100px;
        right: 200px;
        cursor: pointer;
    }
</style>
