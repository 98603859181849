<template>
  <div class="home">
    <img :src="imgUrl" class="home-bg-style" alt="background-image"/>
    <div class="home-center-style">
      <Header/>
      <router-view/>
      <el-dialog :visible.sync="wechatDialog" width="400px" class="dialog-style">
        <wxlogin
            appid="wxb2f5c980f4576e25"
            :scope="'snsapi_login'"
            :theme="'black'"
            :redirect_uri="encodeURIComponent('https://www.hanlinge.cn/#/wx?path='+path)"
        >
        </wxlogin>
      </el-dialog>
      <el-dialog :visible.sync="showBindPhoneNum" width="400px" class="dialog-style">
        <BindPhoneNum @cancleClick="cancelClick" @verifyOK="verifyOK"></BindPhoneNum>
      </el-dialog>
    </div>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import wxlogin from "vue-wxlogin";
import BindPhoneNum from './components/BindPhoneNum'

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    wxlogin,
    BindPhoneNum
  },
  data() {
    return {
      imgUrl: '',
      wechatDialog: false,
      showBindPhoneNum: false,
      path: ''
    }
  },
  mounted() {
    this.getBackground()
  },
  methods: {
    getBackground: function () {
      this.$get("/webapi/home/background")
          .then(res => {
            if (res.code === 1) {
              this.imgUrl = res.data.image;
              if (res.data.logo_theme === 1) {
                this.$store.commit("theme", true)
              } else {
                this.$store.commit("theme", false)
              }
            } else {
              this.imgUrl = require('@/assets/home.jpg')
            }
          })
          .catch(res => {
            console.log(res);
            this.imgUrl = require('@/assets/home.jpg')
          })
          .finally(() => {

          })
    },
    //手机号验证通过
    verifyOK(obj) {
      console.log(obj);
      let unionid = localStorage.getItem('unionid');
      let url = '/webapi/login/save_phone';
      let parameter = {
        phone: obj.phoneNum,
        code: obj.verifyNum,
        unionid: unionid
      };
      this.$post(url, parameter).then(resp => {
        if (resp.code === 1) {
          this.$message.success("绑定成功");
          localStorage.setItem('token', resp.data.token);
          this.$store.commit('refresh', true)
        } else {
          this.$message.error(resp.message);
        }
      }).finally(() => {
        this.showBindPhoneNum = false;
      })
    },
    //验证手机号时点击取消
    cancelClick() {
      this.showBindPhoneNum = false;
    },
  },
  watch: {
    '$store.state.wxShow'(newVal, oldVal) {
      console.log(oldVal)
      this.wechatDialog = newVal
    },
    '$store.state.phoneShow'(newVal, oldVal) {
      console.log(oldVal)
      this.showBindPhoneNum = newVal
    },
    wechatDialog(newVal, oldVal) {
      console.log(oldVal)
      if (!newVal) {
        this.$store.commit("wxShow", false)
      }
    },
    showBindPhoneNum(newVal, oldVal) {
      console.log(oldVal)
      if (!newVal) {
        this.$store.commit("phoneShow", false)
      }
    },
    '$store.state.path'(newVal, oldVal) {
      console.log(oldVal)
      this.path = newVal
    },
    '$store.state.refresh'(newVal, oldVal) {
      console.log(oldVal)
      if (newVal) {
        this.$router.go(0)
      }
    }
  }
}
</script>
<style>
body {
  margin: 0 auto;
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: PingFangSC-Regular, sans-serif, PingFangSC-Light, sans-serif, "Microsoft YaHei", arial;
}

.home-bg-style {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.home-center-style {
  width: 1192px;
  /*background-color: yellow;*/
  /* width: 100%; */
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-start-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flex-row-end-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flex-column-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-column-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

</style>
