<template>
    <div class="focus-container-style">
        <div class="focus-left-style">
            <div style="display: flex;flex-direction: row">
                <div class="avatar-style">
                    <img :src="item.follow_member.headimgurl" alt="avatar">
                </div>
                <div style="display: flex;flex-direction: column" class="focus-right-style">
                    <div class="name-style">{{item.follow_member.nickname}}</div>
                    <div style="display: flex;flex-direction: row" class="address-style">
                        <!--<div>{{item.address}}</div>-->
                        <!--<div class="name-margin-style">|</div>-->
                        <!--<div>{{item.work}}</div>-->
                    </div>
                    <div class="focus-border-style">已关注</div>

                </div>
            </div>
            <div class="focus-bottom-style">
                <div class="focus-count-style">
                    <div>{{item.follow_member.befollow_member_count}}</div>
                    <div>创作</div>
                </div>
                <div class="focus-line-style"></div>
                <div class="focus-count-style">
                    <div>{{item.follow_member.befollow_member_count}}</div>
                    <div>粉丝</div>
                </div>
                <div class="focus-line-style"></div>
                <div class="focus-count-style">
                    <div>{{item.follow_member.follow_member_count}}</div>
                    <div>关注</div>
                </div>
            </div>
        </div>
        <div class="products">
            <img  v-for="(subItem,index) in item.befollow_member_material" :src="subItem.preview_image" alt="product" class="focus-img1-style" :key="index">
        </div>

    </div>
</template>

<script>
    export default {
        name: "FocusItem",
        props: ['item'],


        mounted() {
            // this.$parent.changeCss()
            console.log('**************===0');
            console.log(this.item);

        }

    }


</script>

<style scoped>
    .focus-container-style {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 172px;
        margin: 30px;
    }
.products{
    display: flex;
    justify-content: flex-start;
    /*background-color: yellow;*/
    width: 100%;
    padding-left: 20px;
}
    .focus-left-style {
        width: 248px;
        height: 172px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .focus-img1-style {
        width: 248px;
        height: 172px;
        border-radius: 10px;
        cursor: pointer;
      object-fit:cover;

    }

    .focus-img2-style {
        width: 248px;
        height: 172px;
        border-radius: 10px;
        cursor: pointer;
    }

    .avatar-style{
        width: 100px;
        height: 100px;
        background: #F8F8F8;
        border: 4px solid #FFFFFF;
        box-shadow: 0 2px 6px 0 rgba(17, 17, 17, 0.15);
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .avatar-style>img{
        width: 98px;
        height: 98px;
        border-radius: 50px;
    }

    .name-style{
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        margin-top: 9px;
    }

    .address-style{
        font-size: 13px;
        font-weight: 500;
        color: #D2C3C8;
        margin-top: 14px;
    }

    .focus-border-style{
        width: 80px;
        height: 30px;
        background: #F2F3F3;
        border: 1px solid #EEEEEE;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #D2C3C8;
        margin-top: 20px;
    }

    .focus-right-style{
        margin-left: 19px;
    }

    .focus-bottom-style{
        margin-top: 20px;
        width: 200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .focus-count-style{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .focus-count-style>div:first-child{
        font-size: 22px;
        font-weight: normal;
        color: #333333;
    }

    .focus-count-style>div:last-child{
        font-size: 13px;
        font-weight: 500;
        color: #D2C3C8;
    }

    .focus-line-style{
        width: 1px;
        height: 30px;
        background: #F3F3F3;
    }

    .name-margin-style{
        margin: 0 5px;
    }
</style>
