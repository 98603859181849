<template>
    <div v-loading="loading" >

        <!--没数据时-->
        <div v-if="!isHaveContent" class="upload-empty-style">
            <img src="../../assets/empty.png" alt="empty">
            <div>您还没有上传作品哦！</div>
            <el-button type="text" size="small" @click="navigateToUpload">点击上传</el-button>
        </div>

        <!--有数据时-->
        <div v-else >
            <div class="top-btns">
                <div v-for="(item,index) in content" :key="index"
                     :class="item.id === type ? 'tab-item-active' : 'tab-item-inactive'" @click="changeTab(item.id)">
                    {{ item.name }}
                </div>
            </div>

          <TabContent :content="myProductList" class="collection-content-style"/>



<!--          <div class="product-box">-->
<!--                <div @click="itemClick(item)" class="product-item" v-for="(item,index) in myProductList" :key="index">-->
<!--                    <img class="pre-img" :src="item.preview_image"></img>-->
<!--                </div>-->
<!--            </div>-->

            <div class="page-container-style">
                <el-pagination
                        background
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        layout="prev, pager, next,jumper"
                        :page-size="pageSize"
                        :total="totalNum">
                </el-pagination>
            </div>

        </div>



    </div>

</template>

<script>
import TabContent from "../../components/TabContent";
// import Tab from "../../components/Tab";

    export default {
        name: "UploadEmpty",
      components: {TabContent},

        data(){
            return {
                currentPage:1,
                pageSize:20,
                totalNum:0,
                loading: false,

                type:0,
                myProductList: [],
                content:[],

                isHaveContent:false,


            }
        },
        mounted(){
            this.getButtonOptionData();
            this.loadData();
        },

        methods: {

            itemClick(item){
                this.$router.push({ path:'/item_detail', query:{ id:item.id} })
            },

            changeTab(id){
                this.type = id;
                this.loadData();
            },

            navigateToUpload: function () {
                this.$router.push({
                    name: 'Product'
                })
            },
            loadData: function () {

                let parameter = {
                    per_page:this.pageSize,
                    page:this.currentPage,
                    type:this.type
                };

                this.loading = true;
                this.$get("/webapi/member-material/material_list", parameter)
                    .then(res => {
                        if (res.code === 1) {
                            this.totalNum = res.data.total;
                            this.myProductList = res.data.data
                            if(res.data.data.length > 0 && !this.isHaveContent){

                              this.isHaveContent = true;
                            }
                        } else {
                          console.log(22222222);

                          this.myProductList = [];
                            console.log(res.msg)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },

            //下载按钮选项
            getButtonOptionData() {
                let arr = [{id:0,name:'全部'}];
                // /webapi/material/tabs
                this.$get('/webapi/material/select_tab').then(resp => {
                    // console.log(resp);
                    if(resp.code === 1){
                        this.content = arr.concat(resp.data.type);

                    }else{
                        this.content = arr;
                    }

                }).catch(err => {
                    this.content = arr;
                    console.log(err);
                })
            },


            handleCurrentChange(val) {
                // 点击每页时触发
                this.currentPage = val;
                this.loadData();
            },


        }
    }
</script>

<style scoped>

    .pre-img{
        width: 137px;
        height: 204px;
        object-fit: cover;
        border-radius: 5px;
        box-shadow: 5px 5px 10px #999999;
    }
    .product-box{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .product-item{
        display: inline-flex;
        width: 225px;
        height: 340px;
        background-color: #ECF0F8;
        margin: 30px;
        border-radius: 10px;
        cursor: pointer;

        justify-content: center;
        align-items: center;

    }
    .top-btns {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        padding: 20px;

    }
    .top-btns > div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
    }

    .upload-empty-style {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        flex-direction: column;
    }

    .upload-empty-style > img{
        width: 140px;
        height: 140px;
    }
    .upload-empty-style > div {
        font-size: 14px;
        font-weight: 500;
        color: #BBBBBB;
        margin-top: 10px;
    }
    .page-container-style {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 30px;
    }
    .tab-item-active {
        height: 30px;
        background: linear-gradient(-90deg, #27BAFA, #4253E3);
        box-shadow: 0 4px 9px 0 rgba(42, 176, 248, 0.6);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
    }

    .tab-item-inactive {
        cursor: pointer;
    }
    .collection-content-style {
      margin: 20px 50px;
    }

</style>
