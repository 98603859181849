<template>
    <div class="my-card-style">
        <div class="foot-title-container">
            <div>我的足迹</div>
            <div>My Tracks</div>
        </div>
        <div class="foot-line-style"></div>
        <Tab :tabs="tabs" class="foot-tab-style"/>
        <TabContent :content="contents" class="foot-content-style"/>
    </div>
</template>

<script>
    import Tab from "./Tab";
    import TabContent from "./TabContent";

    export default {
        name: "MyCollection",
        components: {TabContent, Tab},
        data() {
            return {
                tabs: [
                    {id: 0, name: '全部'},
                    {id: 1, name: 'PPT模板'},
                    {id: 2, name: 'Excel图表'},
                    {id: 3, name: '免抠'},
                    {id: 4, name: '图标'},
                    {id: 5, name: '图片'},
                    {id: 5, name: '3D'},
                    {id: 5, name: '视频'},
                ],
                contents: [
                    {id: 0,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 1,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 2,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 3,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 4,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 5,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 6,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 7,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 8,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 9,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 10,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 11,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 12,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 13,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 14,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 15,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 16,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 17,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 18,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 19,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                    {id: 20,url: 'https://preview.qiantucdn.com/58pic/33/64/98/png_auto_19J58PIC0MAcPn0Y0N04G_PIC2018.jpg!w1024_new_small'},
                ]
            }
        },
        mounted() {
            // this.$parent.changeCss()
        }
    }
</script>

<style scoped>
    .my-card-style {
        background: #FFFFFF;
        box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
        border-radius: 10px;
    }

    .foot-title-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        padding-top: 30px;
        width: 100%;
    }

    .foot-title-container > div:first-child {
        margin-left: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #3C434D;
    }

    .foot-title-container > div:last-child {
        font-size: 20px;
        font-weight: 500;
        color: #3C434D;
        opacity: 0.3;
        margin-left: 12px;
    }

    .foot-line-style {
        margin-top: 30px;
        height: 1px;
        width: 100%;
        background: #F4F4F4;
    }

    .foot-tab-style{
        margin-left: 50px;
        margin-top: 20px;
    }

    .foot-content-style {
        margin: 20px 50px;
    }
</style>

