<template>
    <div class="my-card-style">
        <div style="display: flex;justify-content: space-around;align-items: center">
            <div class="upload-title-container">
                <div class="first-title">我的上传</div>
                <div class="sub-title">My upload</div>
            </div>

            <div @click="uploadClick" style="float: right;display: block" class="upload-btn">立即上传</div>

        </div>


        <div class="upload-line-style"></div>
        <router-view />
    </div>
</template>

<script>
    export default {
        name: "MyUpload",
        mounted() {
            // this.$parent.changeCss()
        },
        methods:{
            uploadClick(){
                console.log("((((((((");
                this.$router.push({path:'/me/upload/product'});
            },
        }
    }
</script>

<style scoped>

    .upload-btn{
        height: 50px;
        width: 127px;
        text-align: center;
        background: linear-gradient(-90deg, #27BAFA, #4253E3);
        box-shadow: 0 4px 9px 0 rgba(42, 176, 248, 0.6);
        border-radius: 25px;
        /*display: flex;*/
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
        float: right;
        padding: 0 20px;
        line-height: 50px;
        margin-right: 30px;
        margin-top: 25px;

    }


    .my-card-style{
        min-height: 1239px;
        background: #FFFFFF;
        box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
        border-radius: 10px;
    }

    .upload-title-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        padding-top: 30px;
        width: 100%;
    }

    .first-title{
        margin-left: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #3C434D;
    }

    .sub-title {
        font-size: 20px;
        font-weight: 500;
        color: #3C434D;
        opacity: 0.3;
        margin-left: 12px;
    }

    .upload-line-style {
        margin-top: 30px;
        height: 1px;
        width: 100%;
        background: #F4F4F4;
    }

</style>
