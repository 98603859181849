<template>
    <div class="open-card-style">
        <div>开通粉笔APP会员</div>
        <div>全站会员素材海量下载</div>
        <div></div>
        <div>开通账户：Jocelyn（粉笔会员）</div>
        <div>
            <div></div>
        </div>
        <div>
            <div></div>
            <div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OpenVip"
    }
</script>

<style scoped>
.open-card-style {
    height: 796px;
    background: #FFFFFF;
    box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
    border-radius: 10px;
}
</style>
