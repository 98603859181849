<template>
    <div class="product-container-style" v-loading="loading">
        <div class="upload-container-style">
            <div class="upload-right-style">
                <div><span class="red-star">*</span>作品名称</div>
                <input type="text" v-model="title" class="upload-input-style" placeholder="请输入作品名称"></input>
            </div>
            <div class="upload-waring-style">例: 风格+颜色+内容+类型 (如: 商务风蓝色工作汇报PPT模板)</div>
        </div>
        <div class="upload-container-style">
            <div class="upload-right-style">
                <div><span class="red-star">*</span>作品类型</div>
                <el-select v-model="typeValue" placeholder="请选择作品类型" class="upload-select-style">
                    <el-option
                            v-for="item in typeOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="upload-container-style">
            <div class="upload-right-style">
                <div><span class="red-star">*</span>作品比例</div>
                <input v-model="proportion" type="text" class="upload-input-style" placeholder="请输入作品比例">
            </div>
            <div class="upload-waring-style">例：PPT模板输入PPT尺寸、其他素材请输入分辨率</div>
        </div>

        <div class="upload-container-style">
            <div class="upload-right-style" style="align-items:flex-start">
                <div><span class="red-star">*</span>作品标签</div>

                <div class="upload-input-style2">
                    <div class="upload-input-style3">
                        <span @click="itemClick(1,item.id)" :class="getClassById(1,item.id)" v-for="item in tab1" :key="item.id">{{item.name}}</span>
                    </div>
                  <hr style="background-color: lightgray;border: 0;height: 1px;">
                    <div class="upload-input-style3" style="margin-top: 10px">
                        <span @click="itemClick(2,item.id)" :class="getClassById(2,item.id)" v-for="item in tab2" :key="item.id">{{item.name}}</span>
                    </div>
                  <hr style="background-color: lightgray;border: 0;height: 1px;">
                    <div class="upload-input-style3" style="margin-top: 10px">
                        <span @click="itemClick(3,item.id)" :class="getClassById(3,item.id)" v-for="item in tab3" :key="item.id">{{item.name}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="upload-container-style">
            <div class="upload-right-style">
                <div><span class="red-star">*</span>作品简介</div>
                <textarea v-model="desc" style="height: 80px;padding: 10px" type="text" class="upload-input-style" placeholder="简介限制于100字以内"></textarea>
            </div>
        </div>


        <div class="upload-container-style">
            <div class="upload-right-style" style="align-items: flex-start">
                <div class="upload-area-div"><span class="red-star">*</span>上传预览图</div>
                <el-upload
                        action="https://admin.hanlinge.cn/webapi/common/file/file_put"
                        name="files[]"
                        :limit="1"
                        :data="{file_path:getFilePath('material/image/preview/')}"
                        :on-error="uploadFileError"
                        :on-success="uploadFileSuccessPre"
                        show-file-list>
                    <input class="upload-up-style" placeholder="点击上传预览图" disabled>
                </el-upload>
            </div>
            <div class="upload-waring-style">尺寸建议：960×540px</div>
        </div>


        <div class="upload-container-style">
            <div class="upload-right-style" style="align-items: flex-start">
                <div class="upload-area-div">上传详情图</div>
                <el-upload
                        action="https://admin.hanlinge.cn/webapi/common/file/file_put"
                        name="files[]"
                        :limit="1"
                        :data="{file_path:getFilePath('material/image/desc/')}"
                        :on-error="uploadFileError"
                        :on-success="uploadFileSuccessDetail"
                        show-file-list>
                    <input class="upload-up-style" placeholder="点击上传详情页宣传图" disabled>
                </el-upload>
            </div>
            <div class="upload-waring-style">尺寸建议：1000×1280px（注：除视频类型的素材不用上传此项，其他类型素材都需要上传）</div>
        </div>


        <div class="upload-container-style">
            <div class="upload-right-style" style="align-items: flex-start">
                <div class="upload-area-div">&nbsp; 上传视频</div>
                <el-upload
                        action="https://admin.hanlinge.cn/webapi/common/file/file_put"
                        name="files[]"
                        :limit="1"
                        :data="{file_path:getFilePath('material/file/video/')}"
                        :on-error="uploadFileError"
                        :on-success="uploadFileSuccessVideoUrl"
                        show-file-list>
                    <input size="small" placeholder="点击上传视频" type="primary" class="upload-up-style" disabled>
                </el-upload>
            </div>
            <div class="upload-waring-style">视频格式要求必须为MP4格式，最大不超过200M</div>

        </div>




        <div class="upload-container-style">
            <div class="upload-right-style" style="align-items: flex-start">
                <div  class="upload-area-div"><span class="red-star">*</span>上传源文件</div>
                <el-upload
                        action="https://admin.hanlinge.cn/webapi/common/file/file_put"
                        name="files[]"
                        :limit="1"
                        :data="{file_path:getFilePath('material/file/source/')}"
                        :on-error="uploadFileError"
                        :on-success="uploadFileSuccessOrigin"
                        show-file-list>
                    <input size="small" placeholder="点击上传源文件" type="primary" class="upload-up-style1" disabled>
                </el-upload>
            </div>
            <div class="upload-waring-style">文件大于60M以上建议上传至百度网盘将下载链接保存至rtf文件</div>

        </div>

        <div class="upload-container-style">
            <div class="upload-right-style" style="align-items: flex-start">
                <div class="upload-area-div">上传素材文件</div>
                <el-upload
                        action="https://admin.hanlinge.cn/webapi/common/file/file_put"
                        name="files[]"
                        :limit="1"
                        :data="{file_path:getFilePath('material/file/')}"
                        :on-error="uploadFileError"
                        :on-success="uploadFileSuccessMaterial"
                        show-file-list>
                    <input class="upload-up-style" placeholder="点击上传素材文件"  disabled>
                </el-upload>
            </div>
        </div>


        <div class="upload-container-style">
            <div class="upload-right-style" style="align-items: flex-start">
                <div class="upload-area-div"><span class="red-star">*</span>源文件定价</div>
                <div class="upload-price-style">
                    <input class="upload-price-input-style" v-model="source_file_price"/>
                    <div class="price-unit-style">元</div>
                    <div class="price-note-style">说明：价格最低10元，以10元的整数倍为准，其他价格不允许输入。</div>
                </div>
            </div>
        </div>

        <div class="upload-container-style">
            <div class="upload-right-style" style="align-items: flex-start">
                <div class="upload-area-div">素材文件定价</div>
                <div class="upload-price-style">
                    <input class="upload-price-input-style" v-model="file_price"/>
                    <div class="price-unit-style">元</div>
                    <div class="price-note-style">说明：价格最低10元，以10元的整数倍为准，其他价格不允许输入。</div>
                </div>
            </div>
        </div>



        <div class="upload-line-style1"></div>
        <div class="upload-bottom-style">
            <el-checkbox v-model="agreeCheckBox" class="check-style">我已确认并同意  </el-checkbox><a @click="toProtocol" style="color: #4253E3;cursor: pointer">《翰林阁平台数字作品上传发布使用协议》</a>
        </div>
        <div @click="updateClick" class="btn-upload-style">
            立即上传
        </div>
    </div>
</template>

<script>
    export default {
        name: "UploadProduction",
        data() {
            return {
              authUrl: 'https://www.hanlinge.cn/#/bottomItemView?id=105&title=翰林阁平台数字作品上传发布使用协议&subTitle=Agreement%20for%20the%20Upload%20and%20Release%20of%20Digital%20Works%20on%20the%20Hanlin%20Pavilion%20Platform',
              typeOptions: [],
                tab1: [],
                tab2: [],
                tab3: [],
                selectedTab1:[],
                selectedTab2:[],
                selectedTab3:[],

                typeValue: '',
                title:'',
                desc:'',//描述
                previewImgUrl:'',//预览图
                detailImgUrl:'',//详情图
                videoUrl:'',    //视频
                originUrl:'',   //源文件
                materialUrl:'', //素材文件
                source_file_price:'',//源文件价格
                proportion:'',//比例
                file_price:0,//素材文件价格
                loading: false,
                agreeCheckBox:false,
            }
        },

        mounted() {
            this.selectOptions()
        },

        methods: {

            updateClick(){



                let url = "/webapi/member-material/add_material";

                let parameter = {
                    type:this.typeValue,
                    title:this.title,
                    desc:this.desc,
                    material_industry:this.selectedTab1.join(","),//行业
                    material_style:this.selectedTab2.join(","),//风格
                    material_color:this.selectedTab3.join(","),//颜色
                    preview_image:this.previewImgUrl,
                    desc_image:this.detailImgUrl,
                    desc_video:this.videoUrl,
                    source_file:this.originUrl,
                    source_file_price:this.source_file_price,//源文件价格
                    proportion:this.proportion,//比例
                    file:this.materialUrl,
                    file_price:this.file_price,//素材文件价格
                };
                console.log(parameter);


                if(!this.agreeCheckBox){
                    this.$message.warning("请先同意使用协议");
                    return;
                }

                if(this.stringIsEmpty(this.title) || this.stringIsEmpty(this.proportion) || this.stringIsEmpty(parameter.material_industry)
                    || this.stringIsEmpty(parameter.material_style) || this.stringIsEmpty(parameter.material_color)|| this.stringIsEmpty(this.desc)  || this.stringIsEmpty(this.previewImgUrl) || this.stringIsEmpty(this.originUrl) || this.stringIsEmpty(this.source_file_price)){
                    this.$message.warning("请填写必填项");
                    return;
                }
                //如果传素材了,必须传素材文件价格
                if(!this.stringIsEmpty(this.materialUrl) && this.stringIsEmpty(this.file_price)){
                    this.$message.warning("请填写素材文件价格");
                    return;
                }




                this.$post(url,parameter).then(resp=>{
                    if(resp.code === 1){
                        this.$message.success("上传成功");
                    }else{
                        this.$message.error(resp.msg)
                    }
                }).catch(err=>{
                    this.$message.error("网络错误");
                })

            },

            //预览图
            uploadFileSuccessPre(resp) {
                this.previewImgUrl = resp.data[0];
            },

            //详情图
            uploadFileSuccessDetail(resp){
                this.detailImgUrl = resp.data[0];

            },

            //视频
            uploadFileSuccessVideoUrl(resp){
                this.videoUrl = resp.data[0];
            },
            //源文件
            uploadFileSuccessOrigin(resp){
                this.originUrl = resp.data[0];
            },
            //素材文件
            uploadFileSuccessMaterial(resp){
                this.materialUrl = resp.data[0];
            },

            uploadFileError: function () {
                this.$message.error("上传失败");
            },

            getFilePath(str){
                return str + this.getDateString() + "/"
            },




            getDateString(){
                let nowDate = new Date();
                let year = nowDate.getFullYear();
                let month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1)
                    : nowDate.getMonth() + 1;
                let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate
                    .getDate();
                let dateStr = year + month + day;
                return dateStr;
            },
          toProtocol(){
            window.open(this.authUrl,'_blank')
          },




            selectOptions(){
                let url = "/webapi/material/select_tab";
                this.$get(url,{}).then(resp=>{
                    this.typeOptions = resp.data.type;
                    this.tab1 = resp.data.industry;
                    this.tab2 = resp.data.style;
                    this.tab3 = resp.data.color;
                })
            },


            itemClick(type,id){
                if(type === 1){
                    let index = this.selectedTab1.indexOf(id);
                    if(index === -1){
                        if(this.selectedTab1.length >= 6){
                            this.$message.warning("每种标签最多选择6个");
                            return;
                        }
                        this.selectedTab1.push(id)
                    }else{
                        this.selectedTab1.splice(index,1)
                    }
                }else if(type === 2){
                    let index = this.selectedTab2.indexOf(id);
                    if(index === -1){
                        if(this.selectedTab2.length >= 6){
                            this.$message.warning("每种标签最多选择6个");
                            return;
                        }
                        this.selectedTab2.push(id)
                    }else{
                        this.selectedTab2.splice(index,1)
                    }

                }else if(type === 3){
                    let index = this.selectedTab3.indexOf(id);
                    if(index === -1){
                        if(this.selectedTab3.length >= 6){
                            this.$message.warning("每种标签最多选择6个");
                            return;
                        }
                        this.selectedTab3.push(id)
                    }else{
                        this.selectedTab3.splice(index,1)
                    }

                }
            },

            getClassById(type,id){
                let index = -1;

                if(type === 1){
                    index = this.selectedTab1.indexOf(id);
                }else if(type === 2){
                    index = this.selectedTab2.indexOf(id);

                }else if(type === 3){
                    index = this.selectedTab3.indexOf(id);
                }

                if(index === -1){
                    return "tag-item"
                }else{
                    return "tag-item-sel"
                }
            },



        }
    }
</script>

<style scoped>
    .red-star{
        font-size: 17px;
        color: red;
        margin-right: 3px;
    }
    .upload-container-style {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }

    .upload-right-style > div:first-child {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
    }

    .upload-right-style {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .upload-input-style {
        width: 608px;
        height: 40px;
        padding: 0 20px;
        margin-left: 30px;
        background: #F2F3F3;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        /*color: #333333;*/
        border: none;
        outline: none;
        text-align: left;
        vertical-align: top;
    }

    .upload-input-style2 {
        width: 608px;
        padding: 0 20px;
        margin-left: 30px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        border: none;
        outline: none;
    }
    .upload-input-style3{
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        /*margin: 10px 0;*/
    }
    .tag-item{
        width: 95px;
        height: 40px;
        background-color: #F2F3F3;
        color: #666666;
        margin: 10px;
        border-radius: 20px;
        display: inline-block;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
    }

    .tag-item-sel{
        width: 95px;
        height: 40px;
        background: linear-gradient(-90deg, #27BAFA, #4253E3);
        color: #fff;
        margin: 10px;
        border-radius: 20px;
        display: inline-block;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
    }

    .upload-waring-style {
        margin-top: 8px;
        width: 518px;
        font-size: 14px;
        font-weight: 500;
        color: #BBBBBB;
    }

    .upload-select-style {
        width: 648px;
        height: 40px;
        margin-left: 30px;
        background: #F2F3F3;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        border: none;
        outline: none;
    }

    .upload-tab-style {
        width: 648px;
        height: 40px;
        margin-left: 30px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .tab-bg-style > div {
        width: 95px;
        height: 40px;
        margin-right: 10px;
        background: linear-gradient(-90deg, #27BAFA, #4253E3);
        box-shadow: 0 4px 9px 0 rgba(42, 176, 248, 0.6);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
    }

    .upload-line-style {
        height: 1px;
        background: #ECECEC;
    }

    .upload-tab-bottom-style {
        width: 648px;
        margin-left: 95px;
        margin-top: 20px;
    }

    .check-container-style {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 20px;
    }

    .check-box-style {
        width: 75px;
    }

    .upload-textarea-style {
        width: 628px;
        height: 140px;
        padding: 10px 20px;
        margin-left: 30px;
        background: #F2F3F3;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        border: none;
        outline: none;
        resize: none;
    }

    .upload-area-div {
        margin-top: 8px;
    }

    .upload-up-style {
        width: 628px;
        height: 40px;
        padding: 0 20px;
        margin-left: 30px;
        background: #F2F3F3;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        border: none;
        outline: none;
        resize: none;
    }

    .upload-up-style1 {
        width: 628px;
        height: 40px;
        padding: 0 20px;
        margin-left: 15px;
        background: #F2F3F3;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        border: none;
        outline: none;
        resize: none;
    }

    .product-container-style {
        padding-bottom: 40px;
    }

    .upload-price-style {
        width: 668px;
        margin-left: 30px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    .upload-price-input-style {
        width: 95px;
        height: 40px;
        text-align: center;
        background: #F2F3F3;
        border-radius: 5px;
        border: none;
        outline: none;
        resize: none;
        font-size: 20px;
        font-weight: bold;
        color: #0084FF;
    }

    .price-unit-style{
        font-size: 20px;
        margin-left: 6px;
        font-weight: bold;
        color: #333333;
    }

    .price-note-style {
        font-size: 14px;
        font-weight: 400;
        margin-left: 26px;
        color: #BBBBBB;
    }

    .upload-line-style1 {
        margin-top: 30px;
        margin-left: 145px;
        width: 668px;
        height: 1px;
        background: #ECECEC;
    }

    .upload-bottom-style{
        margin-top: 30px;
        margin-left: 145px;
        width: 668px;
    }

    .check-style{
        font-size: 16px;
        font-weight: 400;
        color: #999999;
    }

    .btn-upload-style{
        width: 270px;
        height: 48px;
        background: linear-gradient(-90deg, #27BAFA, #4253E3);
        box-shadow: 0 4px 9px 0 rgba(42, 176, 248, 0.6);
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        margin-top: 50px;
        margin-left: 296px;
        font-weight: bold;
        color: #FFFFFF;
        cursor: pointer;
    }

    input::placeholder {
        color: #BBBBBB;
    }

    textarea::placeholder {
        color: #BBBBBB;
    }



</style>
