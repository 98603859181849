import axios from 'axios';
import store from "@/store";
import router from "@/router";

let Qs = require('qs');


axios.defaults.timeout = 800000;
axios.defaults.baseURL = 'https://admin.hanlinge.cn';
// axios.defaults.baseURL = "/api";


//http request 拦截器
axios.interceptors.request.use(
    config => {

        config.data = Qs.stringify(config.data);

        console.log(config.url);


        let token = localStorage.getItem('token');

        //测试
        // token = '00a6bda696c480e30ea549bd0b2caa5f9fbe44aac3a20f386e10e295a934981d';


        if (token !== null) {
            config.headers['Authorization'] = token;
        }
        // console.log('token:' + token);

        //   if(config.url !== (Vue.prototype.Global0.passportUrl + 'getUser') && config.url !== '/auth/createtoken'){
        //     let token = getCookie('serverToken');
        //     //这个是请求服务器的
        //       config.headers['token'] = token;
        // }else {
        //   //这个是请求passport的,或者是同步黑词的  不用配置token,因为请求的时候配置过了
        //   //同步黑词: http://210.51.165.120:8889/syncSensitiveWord
        // }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


//http response 拦截器
axios.interceptors.response.use(
    response => {


        if (response.data.code === 10000) {
            store.commit("wxShow", true)
            store.commit('path',router.currentRoute.path)
            return;
        }
        if (response.data.code === 10001 && response.data.msg === 'token不能为空') {
            store.commit("wxShow", true)
            store.commit('path',router.currentRoute.path)
            return;
        }
        if (response.data.data !== undefined && response.data.data !== null && response.data.data.isBinding !== undefined && response.data.data.isBinding !== null && !response.data.data.isBinding) {
            console.log(response.data.data.isBinging)
            store.commit("phoneShow", true)
        }
        return response;
    },
    error => {
        return Promise.reject(error)
    }
);


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {

    console.log(url + '参数:::' + JSON.stringify(params));

    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(response => {
            console.log(url + '返回值:::');
            console.log(response.data);
            resolve(response.data);
        })
            .catch(err => {
                console.log(err);
                reject(err);

                console.log(url + '返回值:::');
            })
    })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @param p     页码 当不需要传页码的时候，需要传一个"-1"
 * @returns {Promise}
 */

export function post(url, data = {}) {
    console.log(axios.defaults.baseURL);
    console.log(url + '参数:::' + JSON.stringify(data));
    return new Promise((resolve, reject) => {
        axios.post(url, data)
            .then(response => {
                console.log(url + '返回值:::');
                console.log(response.data);
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })

}

/**
 * 封装post请求  这个方法用于文件上传时的post
 * @param url
 * @param data
 * @returns {Promise}
 */

export function filePost(url, data = {}) {
    console.log(url);
    console.log('参数:::' + JSON.stringify(data));

    let config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            // 'token':token
        }
    };
    return new Promise((resolve, reject) => {
        const instance = axios.create({
            //这句代码坑我好久,一定注释掉  https://juejin.im/post/5c2490ba6fb9a049ff4e2eca
            // withCredentials:true
        });
        instance.post(url, data, config)
            .then(response => {
                console.log(url + '返回值:::');
                console.log(response.data);
                resolve(response.data);
            }, err => {
                console.log('请求错误');
                reject(err)
            })
    })

}


/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}


export function download(url) {
    try {
        let elemIF = document.createElement("iframe");
        elemIF.src = url;
        elemIF.style.display = "none";
        document.body.appendChild(elemIF);
    } catch (e) {
        this.$message({
            type: 'error',
            message: '下载失败'
        });
    }
}
