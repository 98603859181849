<!--
  Author: wangyuanhao
  Date: 2021/8/17
  Description: 
-->
<template>
    <div>
        <div class="bind-phone-box">
            <div class="phone-title">绑定手机</div>
            <div class="phone-content">
                <el-input v-model="phoneNum" class="phone-num" type="text" placeholder="请输入手机号码"></el-input>
                </div>

            <div class="phone-content">
                <el-input class="verify-num" v-model="verifyNum" type="text" placeholder="请输入验证码"></el-input>
                    <input @click="sendSmsCode" class="verify-btn" type="button" v-model="btnContent" v-bind="{'disabled':disabled}">
            </div>
            <div class="phone-submit clearfix">
                <el-button size="mini" class="submit-btn" type="default" @click="cancleClick" >取 消</el-button>
                <el-button size="mini"  class="submit-btn" type="primary"  @click="OKClick" >确 定</el-button>
            </div>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
    export default {
        data() {
            return {
                phoneNum:"", //手机号
                verifyNum:"", //验证码
                btnContent:"获取验证码", //获取验证码按钮内文字
                time:0, //发送验证码间隔时间
                disabled:false //按钮状态

            }
        },
        created: function () {
        },
        mounted: function () {
        },
        methods: {
            cancleClick(){
                this.$emit("cancleClick")
            },

            OKClick(){
                let obj = {
                    verifyNum:this.verifyNum,
                    phoneNum:this.phoneNum
                };
                this.$emit('verifyOK',obj)
            },

            // 获取验证码
            sendSmsCode(){
                let reg=11&& /^((1)[0-9]{2}\d{8})$/;//手机号正则验证
                let phoneNum = this.phoneNum;
                if(!phoneNum){//未输入手机号
                    // Toast("请输入手机号码");
                    this.$message.error('请输入手机号码');
                    return;
                }
                if(!reg.test(phoneNum)){//手机号不合法
                    this.$message.error('您输入的手机号码不合法，请重新输入');
                    return;
                }

                this.time = 60;
                this.timer();
                // 获取验证码请求
                let url = '/webapi/common/sms/sendSms';
                this.$post(url,{phone:phoneNum,type:1}).then((resp)=>{
                    if(resp.code === 1){
                        this.$message.success("发送成功");
                    }
                });
            },
            timer(){
                if(this.time>0){
                    this.time--;
                    this.btnContent = this.time+"s后重新获取";
                    this.disabled = true;
                    var timer2 = setTimeout(this.timer,1000);
                }else if(this.time === 0){
                    this.btnContent = "获取验证码";
                    clearTimeout(timer2);
                    this.disabled = false;
                }
            },
            // 验证验证码
            verificationCode(){
                var phoneNum = this.phoneNum;//手机号
                var verifyNum = this.verifyNum;//验证码
                var url = 'http://bosstan.asuscomm.com/api/common/verificationCode';
                this.$http.post(url,{
                    username:phoneNum,
                    code:verifyNum
                },{
                    emulateJSON:true
                }).then((response)=>{
                    console.log(response.body);
                });
            },
            fillContent(){
                // console.log("fillContent");
            }
        }
    }
</script>

<style scoped>
.phone-title{
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}
    .phone-content{
        display: flex;
        justify-content: space-between;
        box-sizing:border-box;
        font-size: 12px;

    }
    .phone-num{
        width: 380px;
        margin: 20px 0;
        height: 40px;
        line-height: 40px;
        /*border: 1px lightgray solid;*/
        border-radius: 3px;
        box-sizing:border-box;


    }
    .verify-num{
        width: 60%;
        margin: 0;
        height: 40px;
        line-height: 40px;
        /*border: 1px lightgray solid;*/
        border-radius: 3px;
        display: inline-block;
        box-sizing:border-box;


    }
    .verify-btn{
        width: 35%;
        font-size: 12px;
        padding: 0;
        height: 40px;
        line-height: 40px;
        border: none;
        display: inline-block;
        background-color: orange;
        color: white;
        box-sizing:border-box;
        border-radius: 3px;
        margin-bottom: 20px;
    }
    .phone-submit{
        display: flex;
        justify-content: center;
    }
    .submit-btn{
        margin: 20px 30px;
        min-width: 100px;
    }
</style>