<template>
    <div class="detail-style">
        <div class="detail-title-style">{{title}}</div>

        <div class="detail-content-style" v-if="type !== '2'">{{content}}</div>
        <div v-else class="detail-content-tab-style" >
            <div v-for="(item,index) in content" :key="index">
                <div class="detail-tab-style">{{item.name}}</div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "DetailItem",
        props: ['type', 'title', 'content'],
        mounted() {
            // if (this.type === '2') {
            //     this.content = this.content.toString().split(',')
            // }
        }
    }
</script>

<style scoped>
    .detail-style {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 10px;
    }

    .detail-title-style {
        font-size: 14px;
        font-weight: 500;
        color: #BBBBBB;
        /*width: 48px;*/
        /*background-color: yellow;*/
        display: inline-block;
    }

    .detail-content-style {
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        margin-left: 20px;
        max-width: 180px;
    }
    .detail-content-tab-style {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-left: 10px;
        max-width: 180px;
        flex-wrap: wrap;
        /*background-color: blue;*/
    }
    .detail-tab-style {
        font-size: 12px;
        font-weight: 500;
        color: #494949;
        margin-left: 8px;
        /*width: 58px;*/
        padding: 0 10px;
        height: 20px;
        background: #E0E0E0;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 5px;
    }

</style>
