<!--
  Author: wangyuanhao
  Date: 2021/10/31
  Description:二维码支付界面
-->
<template>
  <div>
    <div class="item-info-box">
      <div class="selected item-info" v-if="ifShowSourceCode()">
        <span class="price-label">{{ payObj0.source_file.price }}元</span>
        <hr class="dash-line" />
        <span class="title-label">{{ payObj0.source_file.title }}</span>
      </div>

      <div class="selected item-info" v-if="ifShowFileCode()">
        <span class="price-label">{{ payObj0.file.price }}元</span>
        <hr class="dash-line" />
        <span class="title-label">{{ payObj0.file.title }}</span>
      </div>
    </div>

    <div class="item-info-box">
      <div v-if="payObj0.source_file !== undefined" class="item-info2">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
          v-if="payObj0.source_file.file_url === ''"
        >
          <img class="qrcode-img" :src="payObj0.source_file.qrcode" alt="" />
          <span style="color: #333; font-size: 16px">微信支付</span>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
          v-else
        >
          <div class="detail-right-top-btn-style" @click="downloadSourceFile">
            <div>下载</div>
            <img src="../assets/download.png" alt="down" />
          </div>
        </div>
      </div>

      <div v-if="payObj0.file !== undefined" class="item-info2">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
          v-if="payObj0.file.file_url === ''"
        >
          <img class="qrcode-img" :src="payObj0.file.qrcode" alt="" />
          <span style="color: #333; font-size: 16px">微信支付</span>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
          v-else
        >
          <div class="detail-right-top-btn-style" @click="downloadFile">
            <div>下载</div>
            <img src="../assets/download.png" alt="down" />
          </div>
        </div>
      </div>
    </div>

    <div class="discounts-box">
      <input placeholder="请输入优惠码" type="text" v-model = promotionCode class="discounts-input">
      <div @click="conversionClick" class="discounts-btn">兑换</div>
    </div>
<div style="padding: 20px 25% 0 25%;color: #666666;">
  {{codeMsg}}
</div>

<div style="text-align: center">
  <hr style="display: inline-block; width: 60%;margin: 40px 0 30px 0; background-color: lightgray;border: none;height: 1px">
</div>
<div style="margin-bottom: 15px">
  <span class="buy-vip-text">购买粉笔会员：</span>
</div>
    <div style="text-align: center;">
      <a href="http://fboffice-pc.duanshu.com/" target="_blank">
      <img style="width: 60%" src="../assets/payBottomImg.jpg" alt="" >
      </a>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "QRCodePay",
  props: {
    payObj: Object,
  },

  data() {
    return {
      //是否可以不付款直接下载
      isFileCanLoad:false,
      isSourceFileCanLoad:false,

      currnetOrderId: "",
      timer: null,
      codeMsg:"", //优惠码返回的信息
      promotionCode:"",//优惠码
      payObj0: this.payObj

    };
  },
  created: function () {},

  beforeDestroy: function () {
    //不会走,不知为啥
    console.log("*****************xiaohui");
  },

  mounted: function () {
    // if (!this.ifShowSourceCode()) {
    //     //不展示源文件,直接下载
    //     this.downloadSourceFile();
    //     console.log("下载源文件");
    // }

    // if (!this.ifShowFileCode()) {
    //     //不展示素材文件,直接下载
    //     this.downloadFile();
    //     console.log("下载素材文件");
    // }

    // if (!this.ifShowSourceCode() && !this.ifShowSourceCode()) {
    //     //两个都是直接下载的,则直接移除弹框
    //     this.$emit('dismissSelf');
    // }
    //默认展示第一个二维码,没有第一个,展示第二个
    clearInterval(this.timer);
    //轮询查询支付结果
    this.checkPayResult();

    if(this.payObj0.file !== undefined && this.payObj0.file.url === ''){
      //需要扫描下载
      this.isFileCanLoad = false
    }else if(this.payObj0.file !== undefined && this.payObj0.file.url !== ''){
      this.isFileCanLoad = true
    }

    if(this.payObj0.source_file !== undefined && this.payObj0.source_file.url === ''){
      //需要扫描下载
      this.isSourceFileCanLoad = false
    }else if(this.payObj0.source_file !== undefined && this.payObj0.source_file.url !== ''){
      this.isSourceFileCanLoad = true
    }



  },

  methods: {
    conversionClick(){
      if(this.stringIsEmpty(this.promotionCode)){
        this.$message.error("请输入优惠码");
        return;
      }
      let url = "/webapi/order/use_material_coupon";
      let parameter = {
        coupon:this.promotionCode
      }
      let orderArr = [];
      // if(this.payObj0.file.order_no != undefined)
        if(this.payObj0.file !== undefined && !this.stringIsEmpty(this.payObj0.file.order_no)){
          orderArr.push(this.payObj0.file.order_no)
        }
      if(this.payObj0.source_file !== undefined && !this.stringIsEmpty(this.payObj0.source_file.order_no)){
        orderArr.push(this.payObj0.source_file.order_no)
      }
      if(orderArr.length > 0){
        parameter['order_no'] = orderArr;
      }

    this.$post(url,parameter).then(resp=>{
      if(resp.code === 1){
        this.payObj0 = resp.data;
        this.codeMsg = resp.msg;
      }else{
        this.codeMsg = resp.msg;
        // this.$message.warning(resp.msg);
      }

    })

    },
    //直接下载的文件,请求接口,记录一下
    reportDownload(type) {
      if (type === 1){
        //素材文件
        if(this.isFileCanLoad){
          let url = "/webapi/order/material_download"
          this.$post(url,{order_no:this.payObj0.file.order_no}).then(resp=>{
            console.log("记录直接下载素材文件...");
            console.log(resp.msg);
          })
        }
      }else {
        //资源文件
        if(this.isSourceFileCanLoad){
          let url = "/webapi/order/material_download"
          this.$post(url,{order_no:this.payObj0.source_file.order_no}).then(resp=>{
            console.log("记录直接下载资源文件...");
            console.log(resp.msg);
          })
        }
      }


    },
    //下载源文件
    downloadSourceFile() {
      if (
        this.payObj0.source_file !== undefined &&
        !this.stringIsEmpty(this.payObj0.source_file.file_url)
      ) {
        this.$download(this.payObj0.source_file.file_url);
        this.reportDownload(2)

      }
    },
    //下载素材文件
    downloadFile() {
      if (
        this.payObj0.file !== undefined &&
        !this.stringIsEmpty(this.payObj0.file.file_url)
      ) {
        this.$download(this.payObj0.file.file_url);
        this.reportDownload(1)
      }
    },

    ifShowSourceCode() {
      return this.payObj0.source_file !== undefined;
    },
    ifShowFileCode() {
      return this.payObj0.file !== undefined;
    },

    stopCheckPayResult() {
      clearInterval(this.timer);
    },

    checkPayResult() {
      this.timer = setInterval(() => {
        setTimeout(() => {
          let url = "/webapi/order/pay_result";
          if (this.payObj0.source_file !== undefined && this.payObj0.source_file.file_url === "") {
            let parameter_source_file = {
              order_no: this.payObj0.source_file.order_no,
            };
            this.$post(url, parameter_source_file).then((resp) => {
              // 支付状态1、未支付   5、已支付 10、支付失败
              if (
                resp.data.is_pay === 5 &&
                !this.stringIsEmpty(resp.data.file)
              ) {
                this.payObj0.source_file.file_url = resp.data.file;
              }
            });
          }


          if (this.payObj0.file !== undefined && this.payObj0.file.file_url === "") {
            let parameter_file = { order_no: this.payObj0.file.order_no };
            this.$post(url, parameter_file).then((resp) => {
              // 支付状态1、未支付   5、已支付 10、支付失败
              if (
                resp.data.is_pay === 5 &&
                !this.stringIsEmpty(resp.data.file)
              ) {
                this.payObj0.file.file_url = resp.data.file;
              }
            });
          }
        }, 0);
      }, 3000);
    },
  },
};
</script>

<style scoped>
.item-info-box {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.item-info {
  display: inline-block;
  margin: 20px 50px;
  width: 298px;
  /*height: 155px;*/
  background: #ffffff;
  /*border: 1px solid #0084FF;*/
  box-shadow: 0px 6px 13px 0px rgba(117, 166, 255, 0.25);
  border-radius: 10px;
}

.selected {
  border: 1px solid #0084ff;
}

.noselected {
  border: 1px solid #bababa;
}

.price-label {
  width: 100%;
  text-align: center;
  display: inline-block;
  height: 27px;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #0084ff;
  line-height: 27px;
  margin-top: 36px;
}

.dash-line {
  border-top: 1px dashed #bdbdbd;
  height: 1px;
  margin: 20px 30px;
}

.title-label {
  display: inline-block;
  height: 20px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  margin: 0 20px 30px 20px;
  text-align: center;
}

.qrcode-img {
  width: 176px;
  height: 176px;
  /*background-color: yellow;*/
}

.item-info2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 20px 50px;
  width: 298px;
  /*height: 155px;*/
  background: #ffffff;
  /*border: 1px solid #0084FF;*/
  border-radius: 10px;
}

.detail-right-top-btn-style {
  width: 180px;
  height: 48px;
  background: linear-gradient(-90deg, #27bafa, #4253e3);
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.detail-right-top-btn-style > div {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}

.detail-right-top-btn-style > img {
  width: 22px;
  height: 22px;
  margin-left: 10px;
}

.discounts-box{
  text-align: center;
  position: relative;
}
.discounts-input{
  box-sizing: border-box;
  padding-left: 30px;
  width: 50%;
  height: 55px;
  background: #FFFFFF;
  border: 1px solid #0084FF;
  box-shadow: 0px 6px 13px 0px rgba(117, 166, 255, 0.25);
  border-radius: 28px;}

.discounts-btn{
  box-sizing: border-box;
line-height: 55px;
  color: white;
  font-size: 20px;
  width: 120px;
  height: 55px;
  background: linear-gradient(-90deg, #27BAFA, #4253E3);
  box-shadow: 0px 4px 9px 0px rgba(42, 176, 248, 0.6);
  border-radius: 28px;
  position: absolute;
  right: 25%;
  top: 0;
  cursor: pointer;

}
.buy-vip-text{
  width: 190px;
  height: 29px;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #0084FF;
  line-height: 11px;
  padding-left: 25%;
}
</style>