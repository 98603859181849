<template>
    <div class="tab-container-style">
        <div v-for="(item,index) in tabs" :key="index">
            <div :class="index === active ? 'tab-item-style-active' : 'tab-item-style-inactive'"
                 @click="tabClick(item.id)">{{item.name}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Tab",
        props: ['tabs'],
        data() {
            return {
                active: 0
            }
        },
        mounted() {

        },
        methods: {
            tabClick: function (id) {
                console.log(id);
                this.active = id;
                this.$emit('tabClick',id)
            }
        }
    }
</script>

<style scoped>
    .tab-container-style {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .tab-item-style-active {
        width: 100px;
        height: 48px;
        background: linear-gradient(-90deg, #27BAFA, #4253E3);
        box-shadow: 0 4px 9px 0 rgba(42, 176, 248, 0.6);
        border-radius: 24px;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .tab-item-style-inactive {
        width: 100px;
        height: 48px;
        border-radius: 24px;
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
</style>
