<template>
  <div>
    <div class="my-card-style">
      <div class="focus-title-container">
        <div>购买素材交易记录</div>
        <div>Purchase material transaction</div>
      </div>
      <div class="focus-line-style"></div>
      <div style="display: flex;justify-content: space-between;align-items: center">
        <div>
          <div style="display: flex;align-items: center" class="input-container-style">
            <div class="number-style">编号 <el-input v-model="sn" type="text" size="medium" style="width: 180px"></el-input> </div>
            <div class="number-style3">类型
              <el-select v-model="type" size="medium" style="width: 180px" placeholder="请选择文件类型">
                <el-option
                    v-for="item in typeOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>

            </div>
          </div>
          <div class="number-style2">日期
            <el-date-picker
                class="date-picker"
                style="width: 200px"
                size="medium"
                v-model="startTime"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
            至
            <el-date-picker
                style="width: 200px"
                class="date-picker"
                size="medium"
                v-model="endTime"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
        <div class="search-style" @click="searchClick">查询</div>
      </div>
      <el-table
          :header-row-style="{backgroundColor: '#F2F3F3'}"
          class="table-container-style"
          :data="tableData">
        <el-table-column
            align="center"
            prop="order_no"
            label="订单编号"
            width="160"
        >
        </el-table-column>

        <el-table-column
            align="center"
            prop="material.sn"
            label="作品编号"
            width="120"
        >
        </el-table-column>

        <el-table-column
            align="center"
            prop="created_at"
            label="日期">
          <template #default="scope">
            {{
              getDate(scope.row.created_at)
            }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="material.type_name"
            label="类型">
        </el-table-column>
        <el-table-column
            align="center"
            prop="price"
            label="交易金额">
          <template #default="scope">
            {{ scope.row.price }}元
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="bottom">
      <div class="message">共<i class="blue">{{ totalNum }}</i>条记录，当前显示第&nbsp;<i class="blue">{{currentPage}}</i> 页
      </div>
      <!--下面是element-ui的分页-->
      <el-pagination class="el-pagin"
                     layout="prev, pager, next"
                     :current-page="currentPage"
                     :total="totalNum"
                     :page-size="pageSize"
                     @current-change="handleCurrentChange">
      </el-pagination>
    </div>

  </div>

</template>

<script>
export default {
  name: "MyRecords",
  data() {
    return {
      startTime: '',
      endTime: '',
      type: 0,
      sn: '',
      pageSize: 20,
      totalNum: 0,
      currentPage: 1,
      typeId: 0,

      tableData: [],
      typeOptions: [],
    }
  },
  mounted() {
    this.getTypeOptions();
    this.loadData();


  },


  methods: {
    getDate(dateTime) {
      let arr = dateTime.split(' ');
      if (arr.length > 0) {
        return arr[0]
      }
      return ""
    },
    searchClick() {
      this.loadData();
    },

    loadData() {
      let url = '/webapi/order/order_list';
      let parameter = {
        per_page: this.pageSize,
        page: this.currentPage,
        type: this.type,
        sn: this.sn,
        start_time: this.startTime,
        end_time: this.endTime
      };
      this.$get(url, parameter).then(resp => {
        this.tableData = resp.data.data;
        this.totalNum = resp.data.total;
      })
    },
    handleCurrentChange(val) {
      // 点击每页时触发
      this.currentPage = val;
      this.loadData();
    },

    getTypeOptions() {
      let url = "/webapi/material/tabs";
      this.$get(url, {t: 'type'}).then(resp => {
        this.typeOptions.push({id:0,name:"全部"})
        this.typeOptions.push(...resp.data)

        // this.typeOptions = resp.data;


      })
    }
  },


}
</script>

<style scoped>
.my-card-style {
  height: 950px;
  background: #FFFFFF;
  box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
  border-radius: 10px;
}

.focus-title-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding-top: 30px;
  width: 100%;
}

.focus-title-container > div:first-child {
  margin-left: 60px;
  font-size: 22px;
  font-weight: bold;
  color: #3C434D;
}

.focus-title-container > div:last-child {
  font-size: 20px;
  font-weight: 500;
  color: #3C434D;
  opacity: 0.3;
  margin-left: 12px;
}

.focus-line-style {
  margin: 30px;
  height: 1px;
  width: 100%;
  background: #F4F4F4;
}

.table-container-style {
  width: 742px;
  margin: 30px auto;
}

.input-container-style {
  margin-left: 80px;
}

.number-style {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}

.number-style2 {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  margin-left: 80px;
  margin-top: 30px;
}

.number-style3 {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  margin-left: 30px;
  /*margin-top: 25px;*/
}

.input-style {
  margin-top: 30px;
  width: 180px;
  height: 30px;
  padding: 0 10px;
  background: #F2F3F3;
  border: 1px solid #0084FF;
  border-radius: 5px;
  outline: #0084FF;
}

.search-style {
  margin: 30px 90px 0 0;
  width: 82px;
  height: 30px;
  background: linear-gradient(-90deg, #27BAFA, #4253E3);
  border: 1px solid #0084FF;
  box-shadow: 0 4px 9px 0 rgba(42, 176, 248, 0.6);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*.date-picker {*/
/*  width: 500px;*/
/*}*/

</style>
