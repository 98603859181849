<template>
    <div class="my-card-style">
        <div class="collection-title-container">
            <div>我的收藏</div>
            <div>My collection</div>
        </div>
        <div class="collection-line-style"></div>
        <Tab @tabClick="tabClick" :tabs="tabs" class="collection-tab-style"/>
        <TabContent :content="contents" class="collection-content-style"/>

        <div class="bottom">
            <div class="message">共<i class="blue">{{totalNum}}</i>条记录，当前显示第&nbsp;<i class="blue">{{currentPage}}</i>页</div>
            <!--下面是element-ui的分页-->
            <el-pagination class="el-pagin"
                           layout="prev, pager, next"
                           :current-page="currentPage"
                           :total="totalNum"
                           :page-size="pageSize"
                           @current-change="handleCurrentChange">
            </el-pagination>

        </div>

    </div>
</template>

<script>
    import Tab from "./Tab";
    import TabContent from "./TabContent";

    export default {
        name: "MyCollection",
        components: {TabContent, Tab},
        data() {
            return {
                pageSize:20,
                totalNum:0,
                currentPage:1,
                typeId:0,
                tabs: [
                    {id: 0, name: '全部'},
                    {id: 1, name: 'PPT模板'},
                    {id: 2, name: 'Excel图表'},
                    {id: 3, name: '免抠'},
                    {id: 4, name: '图标'},
                    {id: 5, name: '图片'},
                    {id: 6, name: '3D'},
                    {id: 7, name: '视频'},
                ],
                contents: []
            }
        },
        mounted() {
            this.loadData();

        },
        methods:{
            tabClick(id){
                this.typeId = id;
                this.loadData();
            },

            loadData(){
                let url = '/webapi/member/collect_list';
                let parameter = {
                    page:this.currentPage,
                    type:this.typeId

                };
                this.$get(url,parameter).then(resp=>{
                    this.contents = resp.data.data;
                    this.totalNum = resp.data.total;
                })
            },

            handleCurrentChange(val) {
                // 点击每页时触发
                this.currentPage = val;
                this.loadData();
            },


        },


    }
</script>

<style scoped>
    .message {
        float: left;
        margin-left: 10px;
        display: inline-block;
        height: 35px;
        line-height: 35px;
        vertical-align: middle;
        margin-top: 2px;

    }

    .blue {
        color: #056dae;
        font-style: normal;
    }

    .el-pagin {
        float: right;
        height: 35px;
        line-height: 35px;
        margin-top: 3px;
    }

    .my-card-style {
        background: #FFFFFF;
        box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
        border-radius: 10px;
    }

    .collection-title-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        padding-top: 30px;
        width: 100%;
    }

    .collection-title-container > div:first-child {
        margin-left: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #3C434D;
    }

    .collection-title-container > div:last-child {
        font-size: 20px;
        font-weight: 500;
        color: #3C434D;
        opacity: 0.3;
        margin-left: 12px;
    }

    .collection-line-style {
        margin-top: 30px;
        height: 1px;
        width: 100%;
        background: #F4F4F4;
    }

    .collection-tab-style{
        margin-left: 50px;
        margin-top: 20px;
    }

    .collection-content-style {
        margin: 20px 50px;
    }
</style>

