import Vue from "vue";
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        wxShow: false,
        phoneShow: false,
        theme: true,
        //搜索相关
        searchData:'',//搜索关键字
        searchModule:'',
        searchType:'',
        searchIndustry:'',
        searchStyle:'',
        searchColor:'',
        beginSearch:false,
        path: '',
        refresh: false,
        avatar: '',
        name: '',
        userId: '',
        fans: '',
        focus: '',
        refreshMessageNum:false,

    },
    mutations: {
        wxShow(state, isShow) {
            state.wxShow = isShow
        },
        phoneShow(state, isShow) {
            state.phoneShow = isShow
        },
        theme(state,isTheme) {
            state.theme = isTheme
        },
        path(state,path) {
            state.path = path
        },
        refresh(state,refresh) {
            state.refresh = refresh
        },
        searchData(state,data){
            state.searchData = data;
        },
        searchType(state,data){
            state.searchType = data
        },
        searchIndustry(state,data){
            state.searchIndustry = data
        },
        searchStyle(state,data){
            state.searchStyle = data
        },
        searchColor(state,data){
            state.searchColor = data
        },
        searchModule(state,data){
            state.searchModule = data;
        },
        beginSearch(state){
            state.beginSearch = !state.beginSearch
        },
        changeUser(state,user) {
            state.avatar = user.headimgurl;
            state.name = user.nickname;
            state.userId = user.id;
            state.fans = user.follow_member_count;
            state.focus = user.befollow_member_count;
        },
        refreshMessageNum(state){
            state.refreshMessageNum = !state.refreshMessageNum;
        },
    },
})

export default store;