<template>
  <div class="my-card-style" v-loading.sync="loading">
    <div class="detail-title-container">
      <div>个人资料</div>
      <div>Personal data</div>
    </div>
    <div class="detail-line-style"></div>
<!--    <div class="detail-avatar-style" @click="changeAvatar">-->
    <div class="avert-box" @click="changeAvatar">
      <img :src="userObj.headimgurl" :onerror="errorImg01" />
      <div class="change-avatar">修改头像</div>
    </div>

    <div class="detail-container-style">
      <div>性别</div>
      <div
        v-if="isChange || userObj.sex === '男'"
        :class="[
          userObj.sex === '男' ? 'detail-sex-active' : 'detail-sex-inactive',
        ]"
        @click="changeSex('男')"
        id="man"
      >
        <img
          :src="[userObj.sex === '男' ? manImgActive : manImgInActive]"
          class="detail-sex-img-style"
          alt="man"
        />
        <div class="detail-sex-name-style">男</div>
      </div>
      <div
        v-if="isChange || userObj.sex === '女'"
        :class="[
          userObj.sex === '女' ? 'detail-sex-active' : 'detail-sex-inactive',
        ]"
        @click="changeSex('女')"
        id="woman"
      >
        <img
          :src="[userObj.sex === '女' ? womanImgActive : womanImgInActive]"
          class="detail-sex-img-style"
          alt="woman"
        />
        <div class="detail-sex-name-style">女</div>
      </div>
    </div>
    <div class="detail-container-style">
      <div>昵称</div>
      <input
          class="input-view"
        v-if="isChange"
        placeholder="请输入昵称"
        v-model="userObj.nickname"
      />
      <div v-else>{{ userObj.nickname }}</div>
    </div>
    <div class="detail-container-style">
      <div>出生年月</div>
<!--      <input-->
<!--        v-if="isChange"-->
<!--        placeholder="请输入出生年月"-->
<!--        v-model="userObj.birthday"-->
<!--      />-->
      <el-date-picker
          class="cascader-style"
          v-if="isChange"
          v-model="userObj.birthday"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="选择日期">
      </el-date-picker>

      <div v-else>{{ userObj.birthday }}</div>
    </div>
    <div class="detail-container-style">
      <div>手机号</div>
      <input
          class="input-view"
        v-if="isChange"
        placeholder="请输入手机号"
        type="phone"
        v-model="userObj.phone"
      />
      <div v-else>{{ userObj.phone }}</div>
    </div>
    <div class="detail-container-style">
      <div>所在行业</div>
      <el-select
        v-if="isChange"
        v-model="userObj.job"
        placeholder="请选择行业"
        class="cascader-style"
      >
        <el-option
          v-for="item in jobs"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div v-else>{{ userObj.job }}</div>
    </div>
    <div class="detail-container-style">
      <div>城市</div>
      <el-cascader
        v-if="isChange"
        class="cascader-style"
        :options="options"
        v-model="userObj.city"
      ></el-cascader>
      <div v-else>
        {{ userObj.province_text }},{{ userObj.city_text }},{{
          userObj.area_text
        }}
      </div>
    </div>
    <div class="detail-save-style" @click="changeSave">
      {{ isChange ? "保存" : "修改资料" }}
    </div>

    <div style="margin-top: 30px; color: #999">
      <span style="cursor: pointer" @click="logOutClick">退出登录</span>
    </div>
    <el-dialog title="上传头像" :visible.sync="editAvatarDialog" width="30%">
      <el-row type="flex" justify="center">
        <div class="cropper">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="option.info"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :autoCropWidth="option.autoCropWidth"
            :autoCropHeight="option.autoCropHeight"
            :fixedBox="option.fixedBox"
            @realTime="realTime"
          ></vueCropper>
        </div>
        <div class="previewBox">
          <div :style="previews.div" class="preview">
            <img :src="previews.url" :style="previews.img" />
          </div>
          <el-row type="flex" justify="center">
            <el-upload
              action=""
              :show-file-list="false"
              :auto-upload="false"
              :on-change="uploadImg"
            >
              <el-button size="mini" type="primary"> 更换头像</el-button>
            </el-upload>
          </el-row>
          <br />
          <el-row>
            <el-button
              icon="el-icon-plus"
              circle
              size="mini"
              @click="changeScale(1)"
            ></el-button>
            <el-button
              icon="el-icon-minus"
              circle
              size="mini"
              @click="changeScale(-1)"
            ></el-button>
            <el-button
              icon="el-icon-download"
              circle
              size="mini"
              @click="down('blob')"
            ></el-button>
            <el-button
              icon="el-icon-refresh-left"
              circle
              size="mini"
              @click="rotateLeft"
            ></el-button>
            <el-button
              icon="el-icon-refresh-right"
              circle
              size="mini"
              @click="rotateRight"
            ></el-button>
          </el-row>
        </div>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editAvatarDialog = false">取 消</el-button>
        <el-button type="primary" @click="saveEditAvatar">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import { VueCropper } from "vue-cropper";

export default {
  name: "PersonDetail",
  components: { VueCropper },
  data() {
    return {
      loading: false,
      manImgActive: require("../assets/man_active.png"),
      manImgInActive: require("../assets/man_inactive.png"),
      womanImgActive: require("../assets/woman_active.png"),
      womanImgInActive: require("../assets/woman_inactive.png"),
      errorImg01: 'this.src="' + require("../assets/avatar_circle.png") + '"',
      isChange: false,
      userObj: {},
      avatarURL: require("../assets/avatar_circle.png"),
      editAvatarDialog: false,
      previews: {},
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 剪切后的图片质量（0.1-1）
        full: true, // 输出原图比例截图 props名full
        outputType: "png", // 裁剪生成额图片的格式
        canMove: true, // 能否拖动图片
        original: false, // 上传图片是否显示原始宽高
        canMoveBox: true, // 能否拖动截图框
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 150,
        autoCropHeight: 150,
        fixedBox: true, // 截图框固定大小
      },
      options: [],
      jobs: [
        {
          value: "政府机关",
          label: "政府机关",
        },
        {
          value: "非营利组织",
          label: "非营利组织",
        },
        {
          value: "医疗健康",
          label: "医疗健康",
        },
        {
          value: "IT互联网",
          label: "IT互联网",
        },
        {
          value: "广告营销",
          label: "广告营销",
        },
        {
          value: "文化传媒",
          label: "文化传媒",
        },
        {
          value: "金融投资",
          label: "金融投资",
        },
        {
          value: "教育培训",
          label: "教育培训",
        },
        {
          value: "生活服务",
          label: "生活服务",
        },
        {
          value: "工业制造",
          label: "工业制造",
        },
        {
          value: "交通运输",
          label: "交通运输",
        },
        {
          value: "生产制造",
          label: "生产制造",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
    };
  },
  mounted() {
    this.changeSex(this.sex);
    this.getUserInfoByToken();
    this.getCity();
  },
  methods: {
    logOutClick() {
      let url = "/webapi/member/logout";
      localStorage.setItem("userInfo", "");
      localStorage.setItem("token", "");
      this.$store.commit("changeUser", "");
      this.userObj = {};
      this.$post(url, {}).then((resp) => {
        this.$message.success("已退出");
      });
      this.getUserInfoByToken();
    },
    getUserInfoByToken() {
      //不需要token,token是放header里面的
      let url = "/webapi/login/user_info_by_token";
      this.loading = true;
      this.$get(url, {})
        .then((resp) => {
          if (resp.code === 1) {
            // this.$message.success("获取用户信息成功");
            resp.data.city = [
              resp.data.province,
              resp.data.city,
              resp.data.area,
            ];
            this.userObj = resp.data;
            this.$store.commit("changeUser", this.userObj);
            localStorage.setItem("userInfo", JSON.stringify(resp.data));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getCity() {
      let url = "/webapi/common/region/all_city";
      this.loading = true;
      this.$get(url)
        .then((res) => {
          if (res.code === 1) {
            this.options = this.generateTree(res.data, 0);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    generateTree(allCity, pid) {
      let array = [];
      allCity.forEach((item) => {
        if (item.pid === pid) {
          let child = {
            value: item.id + "",
            label: item.name,
            children: this.generateTree(allCity, item.id),
          };
          array.push(child);
        }
      });
      if (array.length === 0) {
        return null;
      }
      return array;
    },

    changeSex: function (sex) {
      this.userObj.sex = sex;
    },

    changeSave: function () {
      if (this.isChange === true) {
        let url = "/webapi/login/save_user_info";
        this.$post(url, this.userObj).then((res) => {
          console.log(res);
          if (res.code === 1) {
            this.$message.success(res.msg);
            this.getUserInfoByToken();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
      this.isChange = !this.isChange;
    },

    changeAvatar: function () {
      this.editAvatarDialog = true;
      this.option.img = this.avatarURL;
    },

    // 保存头像修改
    saveEditAvatar() {
      this.editAvatarDialog = false;
      this.finish("blob");
    },

    // 放大/缩小
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },

    // 左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },

    // 右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },

    // 保存上传图片
    finish(type) {
      if (type === "blob") {
        this.$refs.cropper.getCropBlob((data) => {
          this.avatarURL = window.URL.createObjectURL(data);
          //访问接口保存到数据库写这儿!
          let formData = new FormData();
          formData.append("files[]", data);
          formData.append("file_path", "/member/avatar/");
          this.loading = true;
          this.$filePost("/webapi/common/file/file_put", formData)
            .then((res) => {
              if (res.code === 1) {
                this.$post("/webapi/login/save_user_avatar", {
                  headimgurl: res.data[0],
                }).then((res) => {
                  if (res.code === 1) {
                    this.$message.success("更改头像成功");
                    this.getUserInfoByToken();
                  } else {
                    this.$message.error("更改头像失败");
                  }
                });
              } else {
                this.$message.error("更改头像失败");
              }
            })
            .finally(() => {
              this.loading = false;
            });
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          //访问接口保存到数据库写这儿!
          console.log(data);
        });
      }
    },

    // 实时预览函数
    realTime(data) {
      this.previews = data;
    },

    // 下载图片
    down(type) {
      var aLink = document.createElement("a");
      aLink.download = "author-img";
      if (type === "blob") {
        this.$refs.cropper.getCropBlob((data) => {
          aLink.href = window.URL.createObjectURL(data);
          aLink.click();
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          aLink.href = data;
          aLink.click();
        });
      }
    },

    // 更换头像--上传本地图片
    uploadImg(file) {
      var _this = this;
      var reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        _this.option.img = data;
      };
      // 转化为base64
      // reader.readAsDataURL(file.raw)
      // 转化为blob
      reader.readAsArrayBuffer(file.raw);
    },
  },
  watch: {
    $route(to, from) {
      console.log(to);
      console.log(from);
    },
  },
};
</script>

<style scoped>
.my-card-style {
  height: 950px;
  background: #ffffff;
  box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.detail-title-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding-top: 30px;
  width: 100%;
}

.detail-title-container > div:first-child {
  margin-left: 60px;
  font-size: 22px;
  font-weight: bold;
  color: #3c434d;
}

.detail-title-container > div:last-child {
  font-size: 20px;
  font-weight: 500;
  color: #3c434d;
  opacity: 0.3;
  margin-left: 12px;
}

.detail-line-style {
  margin-top: 30px;
  height: 1px;
  background: #f4f4f4;
  width: 100%;
}

.detail-avatar-style {
  width: 122px;
  height: 122px;
  background: #f8f8f8;
  border: 4px solid #ffffff;
  box-shadow: 0 2px 6px 0 rgba(17, 17, 17, 0.15);
  border-radius: 61px;
  margin-top: 40px;
  cursor: pointer;
  margin-bottom: 50px;
}

.detail-avatar-style > img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.avert-box{
  margin-top: 30px;

}

.avert-box > img{
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: solid 4px white;
  box-shadow: 0 2px 6px 0 rgba(17, 17, 17, 0.15);

}

.change-avatar {
  color: #27bafa;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}

/*.detail-avatar-style:hover div {*/
/*color: white;*/
/*font-size: 16px;*/
/*font-weight: bold;*/
/*margin-top: -70px;*/
/*margin-left: 30px;*/
/*visibility: visible;*/
/*}*/

.detail-container-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  height: 40px;
}

.detail-container-style > div:first-child {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  width: 120px;
  text-align: right;
}

.detail-container-style > div:last-child {
  margin-left: 30px;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.detail-sex-active {
  width: 77px;
  height: 40px;
  background: linear-gradient(-90deg, #20dbfe, #75a6ff);
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  color: #ffffff !important;
}

.detail-sex-inactive {
  width: 77px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  color: #93abbd !important;
}

.detail-sex-img-style {
  width: 17px;
  height: 17px;
}

.detail-sex-name-style {
  font-size: 16px;
  font-weight: 500;
  line-height: 5000px;
  margin-left: 10px;
}

.input-view {
  height: 40px;
  background: #f2f3f3;
  border-radius: 5px;
  border: none;
  outline: none;
  padding-left: 20px;
  margin-left: 30px;
  flex: 1;
  margin-right: 45px;
}

.detail-save-style {
  margin-top: 60px;
  width: 270px;
  height: 48px;
  background: linear-gradient(-90deg, #27bafa, #4253e3);
  box-shadow: 0 4px 9px 0 rgba(42, 176, 248, 0.6);
  border-radius: 24px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cascader-style {
  margin-left: 30px;
  flex: 8 !important;
  margin-right: 45px;
  width: 100%;
}

.cascader-style >>> .el-input__inner {
  background: #f2f3f3;
}

.previewBox {
  text-align: center;
  margin-left: 60px;
}

.preview {
  width: 150px;
  height: 150px;
  margin: 0px auto 20px auto;
  border-radius: 50%;
  border: 1px solid #ccc;
  background-color: #ccc;
  overflow: hidden;
}

.cropper {
  width: 260px;
  height: 260px;
}

.el-dialog__body {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<style>
.el-dialog__body {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
