<template>
    <div class="material-container-style" v-loading="loading">

        <div class="material-title-style">
            <div>兼职设计师</div>
            <div>Part time designer</div>
        </div>

        <div style="font-size: 12px;color: #999;padding: 30px 17% 0 23%">
            <span>欢迎您加入翰林阁设计师团队，实现你的梦想，变成你的一技之长。</span>
        </div>


        <div class="material-content-style">

            <div class="one-line">
                <span class="title"><span class="red-star">*</span> <span>真实姓名</span></span>

                <div v-if="!enable" class="cascader-div-style">{{submitData.true_name}}</div>
                <input v-else class="input" v-model="submitData.true_name" placeholder="请输入真实姓名"/>

            </div>

            <div class="one-line">
                <span class="title"> <span class="red-star">*</span> <span>作品</span></span>

                <a
                        class="cascader-div-style"
                        :href="submitData.work_file"
                        v-if="!enable"
                        target="_blank"
                >下载</a>

                <el-upload
                        v-else
                        action="https://admin.hanlinge.cn/webapi/common/file/file_put"
                        class="input"
                        name="files[]"
                        :on-success="onSuccessBack"
                        :on-error="onErrorBack"
                        :limit="1"
                >
                    <div class="upload-file-style"><span>点击上传</span></div>
                </el-upload>
            </div>


            <div class="one-line">
                <span class="title"> <span class="red-star">*</span> <span>现所在地区</span></span>

                <div v-if="!enable" class="cascader-div-style">
                    {{ submitData.region }}
                </div>

                <el-cascader
                        :key="keyValue"
                        style="padding-left: 0"
                        v-else
                        v-model="submitData.region_id"
                        class="input"
                        :props="props"
                        :option="options"
                        @change="handleChange"
                ></el-cascader>

            </div>


            <div class="one-line">
                <span class="title"> <span class="red-star">*</span> <span>上传授权书</span></span>
                <a
                        class="cascader-div-style"
                        :href="submitData.auth_file"
                        v-if="!enable"
                        target="_blank"
                >下载</a>

                <el-upload
                        v-else
                        action="https://admin.hanlinge.cn/webapi/common/file/file_put"
                        class="input"
                        name="files[]"
                        :on-success="onSuccessBack1"
                        :on-error="onErrorBack1"
                        :limit="1"
                        style="justify-content: flex-start"
                >
                    <div class="upload-file-style">点击上传</div>
                </el-upload>

              <div v-if="enable">
                <div class="description">
                  <div>请将授权书打印签字或盖章并扫描上传 <a href="http://files.hanlinge.cn/%20help_docs/109/%E5%90%88%E4%BD%9C%E6%8E%88%E6%9D%83%E4%B9%A6.pdf">授权书下载</a></div>
                  <div>jpg/png格式,10M以内</div>
                </div>
              </div>

            </div>


          <div class="one-line">
            <span class="title"> <span class="red-star">*</span> <span>上传作品权属声明</span></span>
            <a
                class="cascader-div-style"
                :href="submitData.readname_file"
                v-if="!enable"
                target="_blank"
            >下载</a>

            <el-upload
                v-else
                action="https://admin.hanlinge.cn/webapi/common/file/file_put"
                class="input"
                name="files[]"
                :on-success="onSuccessBack2"
                :on-error="onErrorBack2"
                :limit="1"
                style="justify-content: flex-start"
            >
              <div class="upload-file-style">点击上传</div>
            </el-upload>

            <div v-if="enable">
              <div class="description">
                <div>请将作品权属声明打印签字纳印并扫描上传 <a href="http://files.hanlinge.cn/site/%E4%BD%9C%E5%93%81%E5%AE%9E%E5%90%8D%E5%A3%B0%E6%98%8E.pdf">作品权属声明下载</a></div>
                <div>jpg/png格式,10M以内</div>
              </div>
            </div>

          </div>


            <!--如果是设计师不显示下面的内容-->
            <div v-if="enable">

                <el-checkbox text-color="#333" v-model="checked">我已确认并同意 <a style="cursor: pointer" @click="toProtocol">《翰林阁平台数字作品上传发布使用协议》</a></el-checkbox>
            </div>




            <div class="submit-btn" @click="submitMsg" v-if="enable">提交</div>

            <!--<div class="material-toast-style">-->
            <!--<img src="../assets/toast.png" alt="toast"/>-->
            <!--<div>您的需求已提交</div>-->
            <!--</div>-->
        </div>
    </div>

</template>

<script>
    export default {
        name: "Material",
        watch: {
            options(newVal){
               this.keyValue ++;
            }
        },


        data() {
            return {
                options: '',
                keyValue: 0,
                authUrl: 'https://www.hanlinge.cn/#/bottomItemView?id=105&title=翰林阁平台数字作品上传发布使用协议&subTitle=Agreement%20for%20the%20Upload%20and%20Release%20of%20Digital%20Works%20on%20the%20Hanlin%20Pavilion%20Platform',
                checked: false,
                submitData: {
                    true_name: "",  //真实姓名
                    work_file: "",  //素材文件
                    auth_file: "",  //授权文件
                    region: "",      //省市区（拼接好的字符串）
                    region_id: [],
                    province_id: '', //省
                    city_id: '',     //市
                    area_id: '',    //区
                },
                loading: false,
                member_id: "1045303",
                enable: true,
                isDesigner:false,

                props: {
                    lazy: true,
                    lazyLoad: ((node, resolve) => {
                        console.log("*********1");
                        console.log(node.level);
                        console.log(node.value);

                        switch (node.level) {
                            case 0:
                                //获取省级
                                this.$get("/webapi/common/region/province")
                                    .then((res) => {
                                        if (res.code === 1) {
                                            let data = res.data.map((item) => ({
                                                value: item.id,
                                                label: item.sname,
                                                leaf: false,
                                            }));
                                            resolve(data);
                                        } else {
                                            this.$message.error(res.msg);
                                        }
                                    })
                                    .catch((error) => {
                                        this.$message.error(error.message);
                                    });
                                break;
                            case 1:
                                //获取市级
                                this.$get("/webapi/common/region/city", {
                                    province_id: node.value,
                                })
                                    .then((res) => {
                                        if (res.code === 1) {
                                            let data = res.data.map((item) => ({
                                                value: item.id,
                                                label: item.sname,
                                                leaf: false,
                                            }));
                                            resolve(data);
                                        } else {
                                            this.$message.error(res.msg);
                                        }
                                    })
                                    .catch((error) => {
                                        this.$message.error(error.message);
                                    });
                                break;
                            case 2:
                                //获取区级
                                this.$get("/webapi/common/region/area", {
                                    city_id: node.value,
                                })
                                    .then((res) => {
                                        if (res.code === 1) {
                                            let data = res.data.map((item) => ({
                                                value: item.id + "/" + item.mername,
                                                label: item.sname,
                                                leaf: true,
                                            }));
                                            resolve(data);
                                        } else {
                                            this.$message.error(res.msg);
                                        }
                                    })
                                    .catch((error) => {
                                        this.$message.error(error.message);
                                    });
                                break;
                        }
                    }),
                }

            }

        },
        mounted() {
            let userInfo = JSON.parse(localStorage.getItem("userInfo"));
            //是否是设计师
            this.isDesigner = userInfo.origin === 1;
            console.log("是否是设计师");
            console.log(userInfo.origin);


            this.getData();
            this.$get("/webapi/common/region/province")
                .then((res) => {
                    if (res.code === 1) {
                        let data = res.data.map((item) => ({
                            value: item.id,
                            label: item.sname,
                            leaf: false,
                        }));
                        console.log("******");
                       console.log(data);
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });

        },
        methods: {
          toProtocol(){
            window.open(this.authUrl,'_blank')
          },
            handleChange(value) {
                console.log("***********2");
                console.log(value);
                let arr = value[2].split("/");
                this.submitData.region = arr[1];
                this.submitData.region_id[2] = parseInt(arr[0]);
            },
            submitMsg: function () {
                let member_id = localStorage.getItem("member_id");
                if (this.submitData.true_name === "") {
                    this.$message.error("请填写名称");
                    return;
                }
                if (this.submitData.work_file === "") {
                    this.$message.error("请上传作品");
                    return;
                }

                if (this.submitData.auth_file === "") {
                    this.$message.error("请上传授权书");
                    return;
                }

              if (this.submitData.readname_file === "") {
                this.$message.error("作品权属声明");
                return;
              }

                if (this.submitData.region_id.length <= 0) {
                    this.$message.error("请选择城市");
                    return;
                }

                if(!this.checked){
                    this.$message.error("请勾选使用协议");
                    return;
                }

                let parameter = {
                    true_name: this.submitData.true_name,
                    work_file: this.submitData.work_file,
                    auth_file: this.submitData.auth_file,
                    region: this.submitData.region,
                    province_id: this.submitData.region_id[0],
                    city_id: this.submitData.region_id[1],
                    area_id: this.submitData.region_id[2],
                    member_id: member_id === null ? this.member_id : member_id,
                   readname_file:this.submitData.readname_file
                };

                this.loading = true;

                this.$post("/webapi/member/member_designer", parameter)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message.success("提交成功");
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                    .catch((error) => {
                        this.$message.error(error.message);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            uploadFile: function (e) {
                console.log(e);
            },
            onSuccessBack: function (response) {
                console.log(response);
                this.submitData.work_file = response.data[0];
            },
            onErrorBack: function (err) {
                console.log(err);
                this.$message.error("上传失败");
            },

            onSuccessBack1: function (response) {
                console.log(response);
                this.submitData.auth_file = response.data[0];
            },
            onErrorBack1: function (err) {
                console.log(err);
                this.$message.error("上传失败");
            },

          onSuccessBack2: function (response) {
            console.log(response);
            this.submitData.readname_file = response.data[0];
          },
          onErrorBack2: function (err) {
            console.log(err);
            this.$message.error("上传失败");
          },
            getData() {
                this.loading = true;
                this.$get("/webapi/member/member_designer")
                    .then((res) => {
                        if (res.code === 1) {
                            if (res.data === null) {
                                this.enable = true;
                            } else {
                                this.submitData.true_name = res.data.true_name;
                                this.submitData.work_file = res.data.work_file;
                                this.submitData.auth_file = res.data.auth_file;
                                this.submitData.region = res.data.region;
                                this.submitData.readname_file = res.data.readname_file
                                this.submitData.region_id = [
                                    res.data.province_id,
                                    res.data.city_id,
                                    res.data.area_id,
                                ];
                                console.log("************8");
                                console.log(this.submitData.true_name);

                                if (
                                    res.data.approval_status === 5 ||
                                    res.data.approval_status === 15
                                ) {
                                    this.enable = true;
                                } else {
                                    this.enable = false;
                                }
                            }
                        } else {
                            this.$message.error(res.msg);
                            this.enable = false;
                        }
                    })
                    .catch((error) => {
                        this.$message.error(error.message);
                        this.enable = false;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },
    };
</script>

<style scoped>
    .material-container-style {
        height: 1165px;
        box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
        background: white;
        width: 100%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .material-title-style {
        width: 100%;
        height: 140px;
        box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .material-title-style > div:first-child {
        color: #3c434d;
        font-size: 18px;
        font-weight: bold;
    }

    .material-title-style > div:nth-child(2) {
        font-size: 16px;
        color: #3c434d;
        opacity: 0.3;
        font-weight: 500;
        margin-top: 8px;
    }

    .material-content-style {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .one-line {
        margin: 30px 0;
        width: 100%;
        padding-right: 10%;
    }

    .title {
        display: inline-block;
        text-align: right;
        width: 30%;
        padding-right: 20px;
    }

    .input {
        border: none;
        font-size: 15px;
        background-color: #F2F3F3;
        width: 50%;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        padding-left: 20px;
    }
    .upload-file-style{
        width: 550px;
        /*display: inline-block;*/
        color: #BBBBBB;
        text-align: left;
        /*padding-left: 20px;*/

    }


    .red-star {
        color: red;
    }

    * {
        box-sizing: border-box;

    }
    .description{
        color: #808080;
        text-align: left;
        /*padding-bottom: 30px;*/
      padding-left: 30%;
      padding-top: 10px;
    }
    .submit-btn{
        width: 270px;
        height: 48px;
        background: linear-gradient(-90deg, #27BAFA, #4253E3);
        box-shadow: 0px 4px 9px 0px rgba(42, 176, 248, 0.6);
        border-radius: 24px;
        display: inline-block;
        text-align: center;
        line-height: 48px;
        color: white;
        margin-top: 80px;
        cursor: pointer;
    }
    .cascader-div-style{
        display: inline-block;
    }
</style>