<template>
    <div class="my-card-style">
        <div class="focus-title-container">
            <div>我的关注</div>
            <div>My attention</div>
        </div>
        <div class="focus-line-style"></div>
        <div v-for="(item,index) in content" :key="index" >
            <FocusItem  :item="item"/>
            <div style="display: flex;justify-content: flex-end">
                <div class="focus-card-line-style"></div>
            </div>
        </div>

        <div class="bottom">
            <div class="message">共<i class="blue">{{totalNum}}</i>条记录，当前显示第&nbsp;<i class="blue">{{currentPage}}</i>页</div>
            <!--下面是element-ui的分页-->
            <el-pagination class="el-pagin"
                           layout="prev, pager, next"
                           :current-page="currentPage"
                           :total="totalNum"
                           :page-size="pageSize"
                           @current-change="handleCurrentChange">
            </el-pagination>
        </div>


    </div>
</template>

<script>
    import FocusItem from "./Focus/FocusItem";
    export default {
        name: "MyFocus",
        components: {FocusItem},
        data() {
          return {
              pageSize:20,
              totalNum:0,
              currentPage:1,

              content: [
                  // {img: require('../assets/avatar_circle.png'),name: '梅文画',address: '北京',work: '产品经理',create: '20',funs: '20',focus: '34',img1: 'http://preview.qiantucdn.com/58pic/39/80/45/63s58PICyZRpkVKbNPQBP_PIC2018.png!w480_video_nowater',img2: 'http://preview.qiantucdn.com/58pic/36/92/25/04b58PIC4aaE35fbCfCDf_PIC2018.jpg!w480_video_nowater'},
                  // {img: require('../assets/avatar_circle.png'),name: '梅文画',address: '北京',work: '产品经理',create: '20',funs: '20',focus: '34',img1: 'http://preview.qiantucdn.com/58pic/39/80/45/63s58PICyZRpkVKbNPQBP_PIC2018.png!w480_video_nowater',img2: 'http://preview.qiantucdn.com/58pic/36/92/25/04b58PIC4aaE35fbCfCDf_PIC2018.jpg!w480_video_nowater'},
                  // {img: require('../assets/avatar_circle.png'),name: '梅文画',address: '北京',work: '产品经理',create: '20',funs: '20',focus: '34',img1: 'http://preview.qiantucdn.com/58pic/39/80/45/63s58PICyZRpkVKbNPQBP_PIC2018.png!w480_video_nowater',img2: 'http://preview.qiantucdn.com/58pic/36/92/25/04b58PIC4aaE35fbCfCDf_PIC2018.jpg!w480_video_nowater'},
                  // {img: require('../assets/avatar_circle.png'),name: '梅文画',address: '北京',work: '产品经理',create: '20',funs: '20',focus: '34',img1: 'http://preview.qiantucdn.com/58pic/39/80/45/63s58PICyZRpkVKbNPQBP_PIC2018.png!w480_video_nowater',img2: 'http://preview.qiantucdn.com/58pic/36/92/25/04b58PIC4aaE35fbCfCDf_PIC2018.jpg!w480_video_nowater'},
              ]
          }
        },
        mounted() {
            // this.$parent.changeCss()
            this.loadData();

        },
        methods:{
            handleCurrentChange(val) {
                // 点击每页时触发
                this.currentPage = val;
                this.loadData();
            },

            loadData(){
                let url = '/webapi/member/follow_list';
                let parameter = {
                    page:this.currentPage,
                };
                this.$get(url,parameter).then(resp=>{
                    this.content = resp.data.data;
                    this.totalNum = resp.data.total;
                })
            },


        }
    }
</script>

<style scoped>
    .message {
        float: left;
        margin-left: 10px;
        display: inline-block;
        height: 35px;
        line-height: 35px;
        vertical-align: middle;
        margin-top: 2px;

    }

    .blue {
        color: #056dae;
        font-style: normal;
    }

    .el-pagin {
        float: right;
        height: 35px;
        line-height: 35px;
        margin-top: 3px;
    }

    .my-card-style{
        background: #FFFFFF;
        box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
        border-radius: 10px;
    }
    .focus-title-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        padding-top: 30px;
        width: 100%;
    }

    .focus-title-container > div:first-child {
        margin-left: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #3C434D;
    }

    .focus-title-container > div:last-child {
        font-size: 20px;
        font-weight: 500;
        color: #3C434D;
        opacity: 0.3;
        margin-left: 12px;
    }

    .focus-line-style {
        margin-top: 30px;
        height: 1px;
        width: 100%;
        background: #F4F4F4;
    }

    .focus-card-line-style{
        width: 558px;
        height: 1px;
        background: #F4F4F4;
    }
</style>
