<template>
    <div class="message-container-style">
        <div class="message-title-style">
            <div>消息通知</div>
            <div>Message notification</div>
        </div>
        <div v-loading="loading" class="message-content-style">
            <div class="message-content-title-style">
                <div>
                    <div @click="titleBtnClick(1)" :class="[selectIndex === 1 ? 'selected' : 'normal', 'title-btn']" type="text">站内通知</div>
                    <div @click="titleBtnClick(2)" :class="[selectIndex === 2 ? 'selected' : 'normal', 'title-btn']" type="text">系统公告</div>
                </div>
                <div @click="setAllRead">全部已读</div>
            </div>
            <div class="message-line-style"></div>
            <div>
                <div v-for="(item,index) in messages" :key="item.id" class="message-item-style">
                    <div class="message-item-top">
                        <div v-if="!(item.is_read) == 1" class="message-item-red-style"></div>
                        <div :class="[(item.is_read == 1) ? 'have-read' : 'no-read']">{{item.title}}</div>
                    </div>
                    <div class="message-item-bottom">
                        <div class="message-item-des-style">{{item.content}}</div>
                        <div class="message-item-time-style">{{item.created_at}}</div>
                    </div>
                    <div  class="message-item-line-style"></div>
                </div>
            </div>
        </div>

        <div class="page-container-style">
            <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    layout="prev, pager, next,jumper"
                    :page-size="pageSize"
                    :total="totalNum">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Message",
        data() {
            return {
                selectIndex:1, //1站内通知  2系统公告
                haveRead:true,
                pageSize:10,
                currentPage:1,
                totalNum:0,

                submitData: {
                    name: ''
                },
                messages: [],
                loading:false
            };
        },
        mounted() {
            this.loadData()

        },
            methods:{

            setAllRead(){

                let url = '/webapi/message/message_all_read';
                let parameter = {
                    type:this.selectIndex,
                };

                this.$post(url,parameter).then(resp=>{
                    if(resp.code === 1){
                        this.currentPage = 1;
                        this.loadData();

                        //让header的watch方法里调用一下获取消息数量
                        this.$store.commit("refreshMessageNum");

                    }else{
                        this.$message.error(resp.msg)
                    }
                }).catch(err=>{
                    this.$message.error('网络错误');
                })

            },

            titleBtnClick(index){
                this.selectIndex = index;
                this.currentPage = 1;
                this.loadData()
            },

            handleCurrentChange(val) {
                // 点击每页时触发
                this.currentPage = val;
                this.loadData();
            },

            loadData(){
                let url = '/webapi/message/message_list';
                let parameter = {
                    type:this.selectIndex,
                    per_page:this.pageSize
                };
                this.loading = true;
                this.$get(url,parameter).then(resp=>{
                    if(resp.code === 1){
                        this.totalNum = resp.data.total;
                        this.messages = resp.data.data;
                    }else{
                        this.totalNum = 0
                        this.messages = [];
                    }
                    this.loading = false;
                }).catch(err=>{
                    this.loading = false;
                    this.totalNum = 0
                    this.messages = [];
                })

            }
        },
    }
</script>

<style scoped>
    .message-container-style {
        height: 1165px;
        box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
        background: white;
        width: 100%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .selected{
        border-bottom: #0084FF solid 2px;
        color: #0084FF;
    }
    .normal{
        color: #999;
    }
    .title-btn{
        width: 80px;
        margin-bottom: 20px;
        display: inline-block;
        cursor: pointer;
        text-align: center;
        margin-left: 20px;
        height: 40px;
        line-height: 40px;
    }
    .message-title-style {
        width: 100%;
        height: 140px;
        box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .message-title-style > div:first-child {
        color: #3C434D;
        font-size: 18px;
        font-weight: bold;
    }

    .message-title-style > div:nth-child(2) {
        font-size: 16px;
        color: #3C434D;
        opacity: 0.3;
        font-weight: 500;
        margin-top: 8px;
    }

    .message-content-style {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        overflow: scroll;
        height: 100%;
    }

    .message-content-title-style {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .message-content-title-style>div:nth-child(2) {
        border: 1px solid #0084FF;
        border-radius: 18px;
        width: 90px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #0084FF;
        cursor: pointer;
        padding: 0 10px;
    }

    .message-line-style{
        width: 100%;
        height: 1px;
        background: #E7E7E7;
        margin-top: 10px;
    }

    .message-content-style > div {
        width: 64%;
    }

    .message-content-style > div:first-child {
        margin-top: 60px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
    }

    .message-content-style > div:nth-child(3) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        border-radius: 5px;
        height: 350px;
    }

    .message-toast-style {
        width: 40%;
        height: 90px;
        box-shadow: 0 0 35px 3px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        visibility: hidden;
    }

    .message-toast-style > img {
        width: 40px;
        height: 40px;
    }

    .message-toast-style > div {
        color: #4253E3;
        font-weight: 500;
        font-size: 20px;
        margin-left: 13px;
    }

    .cascader-style {
        margin-left: 30px;
        flex: 8 !important;
        width: 100%;
    }

    .message-item-style{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 30px;
        align-items: center;
    }

    .message-item-top{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
    }

    .message-item-red-style{
        width: 6px;
        height: 6px;
        background: #FF0000;
        border-radius: 50%;
        margin-right: 10px;
    }

    .message-item-bottom{
        margin-top: 8px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
    }

    .message-item-line-style{
        width: 100%;
        height: 1px;
        background: #E7E7E7;
        margin-top: 20px;
    }

    .cascader-style >>> .el-input__inner {
        background: #F2F3F3;
    }

    @media screen and (max-width: 1220px) {
        .message-title-style > div:first-child {
            font-size: 16px;
        }

        .message-title-style > div:nth-child(2) {
            font-size: 14px;
        }

        .message-content-style > div:first-child {
            font-size: 14px;
        }

        .message-content-style > div:nth-child(2) > textarea {
            font-size: 12px;
        }

        .message-content-style > div:nth-child(3) {
            font-size: 14px;
        }

        .message-toast-style {
            height: 80px;
        }

        .message-toast-style > img {
            width: 30px;
            height: 30px;
        }

        .message-toast-style > div {
            font-size: 18px;
        }

        .message-content-style > div:nth-child(2) > div > div {
            font-size: 12px;
        }
    }

    @media screen and (min-width: 1460px) {
        .message-title-style > div:first-child {
            font-size: 20px;
        }

        .message-title-style > div:nth-child(2) {
            font-size: 18px;
        }

        .message-content-style > div:first-child {
            font-size: 18px;
        }

        .message-content-style > div:nth-child(2) > textarea {
            font-size: 14px;
        }

        .message-content-style > div:nth-child(3) {
            font-size: 14px;
        }

        .message-toast-style {
            height: 100px;
        }

        .message-toast-style > img {
            width: 50px;
            height: 50px;
        }

        .message-toast-style > div {
            font-size: 25px;
        }

        .message-content-style > div:nth-child(2) > div > div {
            font-size: 16px;
        }
    }

    .have-read{
        color: #999;
    }
    .no-read{
        color: #333;
    }
    .message-item-des-style{
        color: #999;
    }
    .page-container-style {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 30px 0;
    }

</style>
