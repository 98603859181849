import Vue from 'vue'
import App from './App.vue'
import router from './router'
//引入element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import $ from "jquery"
import 'lib-flexible'
import store from "@/store";
// import axios from "axios";

import "./assets/css/global.css"

Vue.use(ElementUI);
// axios.defaults.baseURL = "https://admin.hanlinge.cn";
// axios.defaults.baseURL = "/api";
// Vue.prototype.http = axios;


import {get, post,filePost,download} from './tools/http'

Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$filePost = filePost;
Vue.prototype.$download = download;

Vue.prototype.stringIsEmpty = function (string) {
    return (string === "" || string === null || string === undefined || string === 'undefined');
};


Vue.config.productionTip = false;
Vue.prototype.$ = $;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
