<!--
  Author: wangyuanhao
  Date: 2021/8/21
  Description: 
-->

<template>
    <div class="material-container-style">
        <div class="material-title-style">
            <div>{{title}}</div>
            <div>{{subTitle}}</div>
        </div>

        <div style="display: inline-block; width: 100%;">
            <div style="padding:0 3%" v-html="rawHtml"></div>
        </div>


    </div>
</template>


<!--<template>-->
    <!--<div>-->
        <!--<el-dialog-->
                <!--:visible.sync="showBottomItemDialog0"-->
                <!--width="450"-->
                <!--:modal="false"-->
                <!--:before-close="closeDialog"-->
                <!--center>-->

                <!---->

        <!--</el-dialog>-->
    <!--</div>-->
<!--</template>-->

<script type="text/ecmascript-6">
    export default {
        // props: {
        //     showBottomItemDialog:{
        //         type: Boolean,
        //         default: false
        //     },
        //     id:{
        //         type:Number,
        //         default:0
        //     },
        //     title:{
        //         type:String,
        //         default:''
        //     }
        //
        // },

        // computed: {
        //     // 计算属性的 getter
        //     showBottomItemDialog0: function () {
        //         // `this` 指向 vm 实例
        //         return this.showBottomItemDialog;
        //     },
        //
        // },





        data() {
            return {
                rawHtml:'',
                id:this.$route.query.id,
                title:this.$route.query.title,
                subTitle:this.$route.query.subTitle,
            }
        },
        created: function () {
        },
        mounted: function () {
            if(this.id !== 0){
                this.getHtmlData();
            }
        },
        methods: {
            getHtmlData(){
                this.$get('/webapi/home/help_docs',{docs:this.id}).then(resp=>{
                   if(resp.code === 1){
                       if(resp.data.content == null){
                           this.rawHtml = '<div style="text-align: center;">暂无数据</div>'
                       }else{
                           this.rawHtml = resp.data.content;
                       }
                   }else{
                       this.$message.error(resp.msg);
                       this.closeDialog();
                   }
                });


            },

            closeDialog(){
                this.$emit('closeDialog')
            }
        }
    }
</script>

<style scoped>

    .material-container-style {
        min-height: 1165px;
        box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
        background: white;
        width: 100%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .material-title-style {
        width: 100%;
        min-height: 140px;
        box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .material-title-style > div:first-child {
        color: #3C434D;
        font-size: 18px;
        font-weight: bold;
    }

    .material-title-style > div:nth-child(2) {
        font-size: 16px;
        color: #3C434D;
        opacity: 0.3;
        font-weight: 500;
        margin-top: 8px;
    }

</style>