<template>
    <div class="hot-tab-style">
        <div>{{tabName}}：</div>
        <div :class="judgeIfSelected(0) ? 'tab-item-active' : 'tab-item-inactive'" @click="changeTab(0)">全部</div>
        <div v-for="(item,index) in content" :key="index"
             :class="judgeIfSelected(item.id) ? 'tab-item-active' : 'tab-item-inactive'" @click="changeTab(item.id)">
            {{ item.name }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "HotTab",
        // props: ['content', 'tabType', 'multiple','tabName'],
        // String, Number, Boolean, Function, Object, Array, Symbol



        props:{
            content:{
              type:Array
            },
            tabType:{
              type:String
            },
            multiple:{
              type:Boolean
            },
            tabName:{
              type:String
            },
            currentType:{
                type: Number,
                default: 0
            }
        },


        data() {
            return {
                selectIds: [0]
            }
        },

        watch: {
            currentType(){
                //素材类型可能会传过来
                this.selectIds = [this.currentType];
                this.$store.commit('searchType', this.currentType);
                // this.$store.commit('beginSearch');//发送消息,刷新搜索请求

                this.initSearchData();

                let parameter = {
                    keywords:this.$store.state.searchData,
                    module:this.$store.state.searchModule,
                    type:this.$store.state.searchType,
                    industry:this.$store.state.searchIndustry,
                    style:this.$store.state.searchStyle,
                    color:this.$store.state.searchColor,
                };

                this.$router.push({path:'/hot_tab',query:parameter})

            }
        },
        mounted(){
           this.initSearchData();
        },



        methods: {

            initSearchData(){
                //初始化 归零
                if(this.tabType === 'module') {
                    this.$store.commit('searchModule', '0');
                }else if(this.tabType === 'type'){
                    //素材类型可能会传过来
                    this.selectIds = [this.currentType];
                    this.$store.commit('searchType', this.currentType);
                }else if(this.tabType === 'industry'){
                    this.$store.commit('searchIndustry', '0');
                }else if(this.tabType === 'style'){
                    this.$store.commit('searchStyle', '0');
                }else if(this.tabType === 'color'){
                    this.$store.commit('searchColor', '0');
                }
            },
            judgeIfSelected(id) {
                return this.selectIds.indexOf(id) > -1;

            },
            changeTab: function (id) {
                if(id === 0){
                    //选全部
                    this.selectIds = [0];

                }else{

                    //选的不是全部,先移除全部
                    let zeroIndex = this.selectIds.indexOf(0);
                    if(zeroIndex > -1){
                        this.selectIds.splice(zeroIndex, 1);
                    }


                    let index = this.selectIds.indexOf(id);
                    if (index > -1) {
                        //包含 则移除
                        this.selectIds.splice(index, 1);
                    } else {
                        //不包含
                        if (this.multiple) {
                            //多选
                            this.selectIds.push(id)
                        } else {
                            //单选
                            this.selectIds = [id];
                        }
                    }
                }

                console.log(this.selectIds);


                let idsStr = this.selectIds.join(',');
                console.log(idsStr);
                console.log(this.tabType);

                if(this.tabType === 'module') {
                    this.$store.commit('searchModule', idsStr);
                }else if(this.tabType === 'type'){
                    this.$store.commit('searchType', idsStr);
                }else if(this.tabType === 'industry'){
                    this.$store.commit('searchIndustry', idsStr);
                }else if(this.tabType === 'style'){
                    this.$store.commit('searchStyle', idsStr);
                }else if(this.tabType === 'color'){
                    this.$store.commit('searchColor', idsStr);
                }
                // this.$store.commit('beginSearch');//发送消息,刷新搜索请求

                let parameter = {
                    keywords:this.$store.state.searchData,
                    module:this.$store.state.searchModule,
                    type:this.$store.state.searchType,
                    industry:this.$store.state.searchIndustry,
                    style:this.$store.state.searchStyle,
                    color:this.$store.state.searchColor,
                };


                this.$router.push({path:'/hot_tab',query:parameter})


            }
        }
    }
</script>

<style scoped>
    .hot-tab-style {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
    }

    .hot-tab-style > div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
    }

    .tab-item-active {
        height: 30px;
        background: linear-gradient(-90deg, #27BAFA, #4253E3);
        box-shadow: 0 4px 9px 0 rgba(42, 176, 248, 0.6);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
    }

    .tab-item-inactive {
        cursor: pointer;
    }
</style>
