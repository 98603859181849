<template>
  <div class="tab-container-style">
    <HotTab :content="module" tabType="module" :multiple="true" tabName="模块"/>
    <HotTab :content="typeOption" tabType="type" :multiple="false" :currentType="type" tabName="素材"/>
    <HotTab :content="industry" tabType="industry" :multiple="true" tabName="行业"/>
    <HotTab :content="style" tabType="style" :multiple="true" tabName="风格"/>
    <HotTab :content="color" tabType="color" :multiple="true" tabName="颜色"/>
  </div>
</template>

<script>
import HotTab from "./HotTab";
export default {
  name: "HotTabHeader",
  components: {HotTab},
    props:{
      type:{
          type:Number,
          default:0
      }
    },//传过来的素材类型

  data() {
    return {
        module:[],
        typeOption: [],
        industry: [],
        style: [],
        color: [],
    }
  },
    mounted() {
      this.getData();
    },

    methods:{
      getData(){
          this.$get('/webapi/material/select_tab').then(resp=>{
            console.log(resp);
            this.module = resp.data.module;
            this.typeOption = resp.data.type;
            this.industry = resp.data.industry;
            this.style = resp.data.style;
            this.color = resp.data.color;
          }).catch(err=>{
              console.log(err);
          })
      }
    }
}
</script>

<style scoped>
.tab-container-style {
  height: 400px;
  box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

.tab-container-style > div {
  margin-left: 30px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.tab-container-style > div:not(:first-child) {
  margin-left: 30px;
  margin-top: 30px;
}

</style>
