<template>
  <div class="carousel-container-style">
    <div class="carousel-tab-style">
      <div v-for="(item,index) in tab" :key="index">
        <div v-if="index === 0" class="carousel-tab-item-style1" @click="navigateToPath(item.type)">{{ item.title }}</div>
        <div v-else class="carousel-tab-item-style2" @click="navigateToPath(item.type)">{{ item.title }}</div>
      </div>
    </div>
    <el-carousel class="carousel-style">
      <el-carousel-item v-for="(item,index) in imgs" :key="index">
        <a :href="item.link" target="_blank">
          <img :src="item.image" alt="carousel-bg" class="carousel-img-style">
        </a>
      </el-carousel-item>
    </el-carousel>
    <!--<div class="hot-tab-style">-->
      <!--<div class="carousel-hot-name">当前热门：</div>-->
      <!--<div v-for="(item,index) in hot" :key="index">-->
        <!--<div class="carousel-hot-item-style" @click="navigateToPath">{{ item }}</div>-->
      <!--</div>-->
    <!--</div>-->
  </div>

</template>

<script>
export default {
  name: "Carousel",
  data() {
    return {
      tab: [
          {
        title:"PPT模板",
        type:1
      },
          {
              title:"Excel图表",
              type:2
          },
          {
              title:"免抠",
              type:3
          },
          {
              title:"图标",
              type:4
          },
          {
              title:"图片",
              type:5
          },
          {
              title:"3D",
              type:6
          },
          {
              title:"视频",
              type:7
          },
      ],
      imgs: [
        "https://t7.baidu.com/it/u=1963005386,1762595881&fm=193&f=GIF",
        "https://t7.baidu.com/it/u=2949154381,564552939&fm=193&f=GIF",
        "https://t7.baidu.com/it/u=3452791716,1408395020&fm=193&f=GIF"
      ],
      hot: [
        "中国风", "商会", "科技", "促销"
      ]
    }
  },
  mounted() {
    this.getCarousels()
  },
  methods: {
    navigateToPath(type) {
      this.$router.push({
          path: '/hot_tab',
          query:{type:type}
      })
    },
    navigateToDetail: function () {
      this.$router.push({
        name: 'ItemDetail'
      })
    },
    getCarousels: function () {
      this.$get("/webapi/home/banner")
      .then(res => {
        if (res.code === 1) {
          this.imgs = res.data
        }else{
          console.log(res.msg)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {

      })
    }
  }
}
</script>

<style scoped>
.carousel-container-style {
  width: 100%;
  height: 450px;
  position: relative;
}

.carousel-tab-style {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  height: 67px;
  top: 0;
  width: 100%;
  z-index: 99;
}

.carousel-img-style{
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.carousel-tab-item-style1 {
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 20px;
  margin-left: 37px;
}

.carousel-tab-item-style2 {
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 20px;
  margin-left: 42px;
}

.carousel-hot-name {
  font-weight: 400;
  color: #FFFFFF;
  font-size: 20px;
}

.carousel-hot-item-style {
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 20px;
  margin-left: 30px;
}

.hot-tab-style {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 99;
  width: 100%;
  bottom: 65px;
}

.carousel-style >>> .el-carousel__container {
  height: 450px !important;
}

.carousel-style >>> .el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
}
</style>
