<template>
  <div class="me-container-style">
    <div class="me-left-style">
      <div class="me-header-style">
<!--        <div class="me-header-avatar-style">-->
        <div class="avert-box">
          <img :src="avatar" v-if="avatar !== ''"/>
        </div>
        <div class="me-header-name">{{ name }}</div>
        <div class="me-header-id-style">ID:{{ userId }}</div>
        <!--        <div class="me-header-cert-container-style">-->
        <!--          <div class="me-header-star-style">-->
        <!--            <img src="../assets/star.png" alt="star" />-->
        <!--            <div>积分</div>-->
        <!--          </div>-->
        <!--          <div class="me-header-num-style">999999</div>-->
        <!--        </div>-->
        <div class="me-header-focus-container-style">
          <div class="me-header-fans-style">
            <div>{{ fans }}</div>
            <div>粉丝</div>
          </div>
          <div class="me-header-focus-line-style"></div>
          <div class="me-header-fans-style">
            <div>{{ focus }}</div>
            <div>关注</div>
          </div>
        </div>
      </div>
      <div class="me-item-container-style">

        <div class="me-center-style">个人中心</div>
        <div :class="[currentRouteName === 'Detail' ? 'select-item':'un-select-item','me-item-style']"
             @click="navigateToUrl('Detail')">
          <div>
            <img v-if="currentRouteName === 'Detail'" src="../assets/selInfoSel.png" alt="">
            <img v-else src="../assets/selInfo.png" alt="">
            <div>个人资料</div>
          </div>
          <img src="../assets/right_hover.png" alt="right"/>
        </div>

        <div class="me-line-style"></div>
        <div :class="[currentRouteName === 'Vip' ? 'select-item':'un-select-item','me-item-style']"
             @click="navigateToUrl('Vip')">
          <div>
            <img v-if="currentRouteName === 'Vip'" src="../assets/myVipSel.png" alt="">
            <img v-else src="../assets/myVip.png" alt="">
            <div>我的会员</div>
          </div>
          <img src="../assets/right_hover.png" alt="right"/>
        </div>
        <div class="me-line-style"></div>
        <div :class="[currentRouteName === 'Download' ? 'select-item':'un-select-item','me-item-style']"
             @click="navigateToUrl('Download')">
          <div>
            <img v-if="currentRouteName === 'Download'" src="../assets/myDownloadSel.png" alt="">
            <img v-else src="../assets/myDownload.png" alt="">
            <div>我的下载</div>
          </div>
          <img src="../assets/right_hover.png" alt="right"/>
        </div>
        <div class="me-line-style"></div>

        <div :class="[currentRouteName === 'Records' ? 'select-item':'un-select-item','me-item-style']"
             @click="navigateToUrl('Records')">
          <div>
            <img v-if="currentRouteName === 'Records'" src="../assets/dealRportSel.png" alt="">
            <img v-else src="../assets/dealRport.png" alt="">
            <div>购买记录</div>
          </div>
          <img src="../assets/right_hover.png" alt="right"/>
        </div>
        <div class="me-line-style"></div>



        <!--        <div class="me-line-style"></div>-->
        <!--        <div :class="[currentRouteName == 'Foot' ? 'select-item':'un-select-item','me-item-style']"-->
        <!--             @click="navigateToUrl('Foot')">-->
        <!--          <div>-->
        <!--            <img src="../assets/toast.png" alt="person"/>-->
        <!--            <div>我的足迹</div>-->
        <!--          </div>-->
        <!--          <img src="../assets/right_hover.png" alt="right"/>-->
        <!--        </div>-->
        <div :class="[currentRouteName === 'Focus' ? 'select-item':'un-select-item','me-item-style']"
             @click="navigateToUrl('Focus')">
          <div>
            <img v-if="currentRouteName === 'Focus'" src="../assets/myFollowSel.png" alt="">
            <img v-else src="../assets/myFollow.png" alt="">
            <div>我的关注</div>
          </div>
          <img src="../assets/right_hover.png" alt="right"/>
        </div>
        <div class="me-line-style"></div>
        <div :class="[currentRouteName === 'Collection' ? 'select-item':'un-select-item','me-item-style']"
             @click="navigateToUrl('Collection')">
          <div>
            <img v-if="currentRouteName === 'Collection'" src="../assets/likeFileSel.png" alt="">
            <img v-else src="../assets/likeFile.png" alt="">
            <div>我的收藏</div>
          </div>
          <img src="../assets/right_hover.png" alt="right"/>
        </div>
        <div class="me-line-style"></div>

        <div :class="[currentRouteName === 'Upload' ? 'select-item':'un-select-item','me-item-style']"
             @click="navigateToUrl('Upload')">
          <div>
            <img v-if="currentRouteName === 'Upload'" src="../assets/myUploadSel.png" alt="">
            <img v-else src="../assets/myUpload.png" alt="">

            <div>我的上传</div>
          </div>
          <img src="../assets/right_hover.png" alt="right"/>
        </div>
        <div class="me-line-style"></div>

        <div :class="[currentRouteName === 'Money' ? 'select-item':'un-select-item','me-item-style']"
             @click="navigateToUrl('Money')">
          <div>
            <img v-if="currentRouteName === 'Money'" src="../assets/mySourceSel.png" alt="">
            <img v-else src="../assets/mySource.png" alt="">
            <div>我的资产</div>
          </div>
          <img src="../assets/right_hover.png" alt="right"/>
        </div>
        <div class="me-line-style"></div>

      </div>
    </div>
    <div class="me-right-style">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Me",
  data() {
    return {
      currentRouteName: 'Detail',
      avatar: '',
      name: '',
      userId: '',
      fans: '',
      focus: '',
    }
  },
  mounted() {
    this.currentRouteName = this.$router.currentRoute.name;
    let userInfo = localStorage.getItem('userInfo');
    let user = JSON.parse(userInfo);
    this.avatar = user.headimgurl;
    this.name = user.nickname;
    this.userId = user.id;
    this.fans = user.follow_member_count;
    this.focus = user.befollow_member_count;
  },
  methods: {
    navigateToUrl(urls) {
      if (urls === this.$router.currentRoute.name) {
        return;
      }
      this.currentRouteName = urls;
      this.$router.push({
        name: urls,
      });
    },
  },
  watch: {
    '$store.state.avatar'(newVal) {
      this.avatar = newVal
    },
    '$store.state.name'(newVal) {
      this.name = newVal
    },
    '$store.state.userId'(newVal) {
      this.userId = newVal
    },
    '$store.state.fans'(newVal) {
      this.fans = newVal
    },
    '$store.state.focus'(newVal) {
      this.focus = newVal
    },
  }
};
</script>

<style scoped>

.select-item {
  opacity: 1.0;
}

.un-select-item {
  opacity: 0.5;
}

.me-container-style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.me-left-style {
  width: 26%;
}

.me-right-style {
  width: 72%;
}

.me-header-style {
  height: 400px;
  background: white;
  box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.me-item-container-style {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0 14px 28px 2px rgba(81, 130, 255, 0.1);
  border-radius: 10px;
  width: 100%;
}

.me-center-style {
  height: 77px;
  background: #ffffff;
  box-shadow: 0 4px 10px 0 rgba(159, 159, 159, 0.12);
  border-radius: 3px;
  display: flex;
  padding-left: 33px;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.me-line-style {
  height: 1px;
  background: #f4f4f4;
  width: 75%;
  float: right;
}

.me-item-style {
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /*opacity: 0.5;*/
}

.me-item-style > img {
  width: 18px;
  height: 18px;
  margin-left: 30px;
  margin-right: 16px;
}

.me-item-style > div > img {
  width: 18px;
  height: 18px;
  margin-left: 30px;
}

.me-item-style > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.me-item-style > div > div {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-left: 25px;
  cursor: pointer;
  width: 100%;
}

.me-item-style:hover {
  opacity: 1;
}

.me-header-avatar-style {
  width: 122px;
  height: 122px;
  background: #eaf5ff;
  border: 4px solid #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(17, 17, 17, 0.15);
  border-radius: 50%;
  margin-top: 30px;
}

.me-header-avatar-style > img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.me-header-name {
  font-size: 26px;
  font-weight: bold;
  color: #333333;
  margin-top: 24px;
}

.me-header-id-style {
  font-size: 18px;
  font-weight: 500;
  color: #999999;
  margin-top: 10px;
}

.me-header-cert-container-style {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.me-header-star-style {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.me-header-star-style > img {
  width: 16px;
  height: 16px;
}

.me-header-star-style > div {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

.me-header-num-style {
  width: 104px;
  height: 24px;
  background: #eff7ff;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  color: #0084ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.me-header-focus-container-style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.me-header-fans-style {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.me-header-fans-style > div:first-child {
  font-size: 30px;
  font-weight: normal;
  color: #333333;
}

.me-header-fans-style > div:last-child {
  font-size: 14px;
  font-weight: 500;
  color: #cccccc;
}

.me-header-focus-line-style {
  width: 1px;
  height: 30px;
  background: #f3f3f3;
}
.avert-box{
  margin-top: 30px;

}

.avert-box > img{
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: solid 4px white;
  box-shadow: 0 2px 6px 0 rgba(17, 17, 17, 0.15);

}

</style>
